@import "../../../index.scss";
.item, .disabledItem {
  font-size: 1.2rem;
  padding: 1rem;
  background-color: #fafafa;
  border: 1px solid #e7e7e7;
  @include flexbox;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #e1e1e1;
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
  @include respond(tablet){
    min-height: 10rem;
  }
  @include respond(phone){
    min-height: 13rem;
  }
}
.disabledItem{
  background-color: #e7e7e7;
  // &:hover {
  //   background-color: #c9c7c7;
  // }
}
.index {
  margin-right: 1rem;
}
.dataWrapper {
  @include flexbox;

}
.dataLabel {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.itemInfoWrapper {
  @include flexbox;
  @include flex(0 0 70%);
  @include flex-direction(column);
  margin-right: auto;
}
.iconWrapper {
  font-size: 1.6rem;
  cursor: pointer;
  color: #beb5b5de;
  border: 1px solid #beb5b5de;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  @include flexbox;
  @include justify-content(center);
  position: absolute;
  top: 40%;
  right: 6%;
}
.icon {
  padding: 0.3rem;
}
