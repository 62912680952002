@import "../../index.scss";

.Headers {
  background: $header-background-color;
  width: 100%;
  @include flexbox;
  @include justify-content(flex-start);
  height: 10rem !important;
  border-radius: 2rem 2rem 0.4rem 0;
  @include align-items(center);
  margin-bottom: 0.7rem;
  @include respond(tablet) {
    border-radius: unset;
  }
  @include respond(phone) {
    border-radius: unset;
    margin-bottom: unset;
    border-radius: 0rem 0rem 0rem 0;
    height: 10rem !important;
  }
  .mobileLogoWrapper {
    display: none;
    @include respond(phone) {
      @include flexbox;
      @include justify-content(flex-end);
      margin-left: 2rem;
      width: 100%;
    }
  }

  .logowrapper {
    margin-left: 2rem;
    width: 100%;
    @include flexbox;
    @include align-items(center);
    @include respond(phone) {
      margin-left: unset;
    }
  }
  .logoDesktop {
    @include flexbox;
    @include align-items(baseline);
    margin-right: auto;
    margin-left: 6rem;
    cursor: pointer;
    @include respond(phone) {
      display: none;
    }
  }
  .imgLogo {
    height: 5rem;
    @include respond( phone){
      width: 10rem;
    }
  }
  .versionNumber {
    font-size: 1.4rem;
    cursor: pointer;
    position: relative;
    z-index: 100;
  }
  .logoMobile {
    margin-right: auto;
    margin-left: 5rem;
    @include flexbox;
    @include align-items(baseline);
  }
  .inputMobileSearchWrapper {
    @include respond(phone) {
      margin-left: 2rem;
      width: 100%;
    }
  }
  .menuIcon {
    font-size: 3rem;
    color: #646464;
    margin-right: 1rem;
    cursor: pointer;
    position: absolute;
    left: 2.5%;
    @include respond(phone) {
      font-size: 2rem;
      margin-left: 0.5rem;
    }
  }
  .searchIcon {
    display: none;
    @include respond(phone) {
      display: block;
      font-size: 2rem;
      margin-right: 4%;
      color: $color-login-page-hiway;
    }
  }
  .searchWrapperDesk {
    position: relative;
    @include flexbox;
    @include align-items(center);
    margin-right: 1rem;
  }
  .IconSearcDesk {
    @include positionCenter;
    left: 75%;
    font-size: 2rem;
    color: $color-login-page-hiway;
    font-weight: 500;
    @include respond(tab-port-big) {
      left: 67%;
    }
    @include respond(phone) {
      display: none;
    }
  }
  .iconHeaderTitle {
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }
  .inputHeaderSearchDesktop {
    padding: 0.375rem 0.75rem;
    line-height: 3;
    background-color: #fff;
    outline: none;
    -webkit-appearance: none;
    border: 0.2rem solid $color-login-page-hiway;
    width: 36rem;
    color: $color-input-font;
    font-size: 1.2rem;
    border-radius: 0.2rem;
    margin-right: 2rem;
    font-family: "Poppins", sans-serif;
    @include respond(tab-port-big) {
      line-height: 2.5;
      width: 26rem;
    }
    @include respond(phone) {
      display: none;
    }
    &:disabled {
      background-color: #e2e2e2;
      cursor: not-allowed;
    }
  }
  .dropdownMenu {
    background-color: $color-login-page-hiway;
    margin-right: 1rem;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 0.2rem;
    position: relative;
    cursor: pointer;
    border: none;
    outline: none;
    @include respond(tablet) {
      width: 4rem;
      height: 4rem;
    }
    @include respond(phone) {
      display: none;
    }
  }
  .dropdownMenu li ul li {
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-size: 1.4rem;
    &:first-child {
      margin-top: 1rem;
    }
  }
  .dropdownMenu li {
    display: block;
    margin: 0 0.2rem 0 0;
    padding: 1.2rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    height: 100%;
  }
  .dropdownMenu ul {
    height: 100%;
  }
  .dropdownMenu ul li ul {
    background-color: #fff;
    visibility: hidden;
    width: 16rem;
    padding: 1rem 1rem;
    height: 14.5rem;
    right: 0px;
    margin: auto;
    border-radius: 1rem;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.26);
    color: black;
    position: absolute;
    @include transition(visibility 0.1s ease-in);
    z-index: 150;
    top: 5rem;
  }

  .dropdownMenu ul li:hover > ul,
  .dropdownMenu li ul:hover {
    visibility: visible;
  }
  .accountEmail {
    overflow: auto;
    width: 12rem;
    text-transform: initial;
  }
  .logoutIcon {
    display: inline-block;
    font-size: 1.6rem;
    @include positionCenter;
    color: #fff;
    text-transform: uppercase;
  }
  .inputSearchWrapper {
    display: none;
  }
  .inputSearchWrapperMobile {
    display: none;
    @include respond(phone) {
      display: block;
      background-color: $color-iot-green-primary;
      @include transition(height 0.15s ease-in-out);
      z-index: 10000;
      position: absolute;
      width: 70%;
    }
  }
  .inputMobileWrapper {
    position: relative;
    margin: 0 2.1rem;
    top: 2rem;
    font-family: "Poppins", sans-serif;
  }
  .inputMobileSearch {
    padding: 0.375rem 0.75rem;
    line-height: 3;
    background-color: #fff;
    outline: none;
    -webkit-appearance: none;
    border: 0.2rem solid $color-login-page-hiway;
    width: 85%;
    color: $color-input-font;
    font-size: 1.2rem;
    border-radius: 0.2rem;
  }
  .logoutfloatingDesk {
    display: none;
    width: 10rem;
    height: 5rem;
    border-radius: 0.2rem;
    background-color: #fff;
    z-index: 20;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  .generalInfo {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  .titleWelcome {
    color: #fff;
    margin-right: 0.5rem;
    font-size: 1.3rem;
    font-weight: 300;
    text-transform: capitalize;
    cursor: pointer;
  }
  .infoUser {
    @include flexbox;
    @include flex-direction(column);
    color: $color-input-font;
  }
  .iconAvatar {
    font-size: 3.2rem;
    color: #fff;
    font-weight: 400;
    margin-right: 1rem;
    color: $icon-text-color;
  }
  .imgAvatar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
  .infoAvatar {
    @include flexbox;
    margin-left: 1rem;
    @include flex-direction(column);
    @include justify-content(center);
    margin-right: 1rem;
    font-size: 1.3rem;
    @include align-items(center);
  }
  .actionLinkWrapper {
    @include flexbox;
    margin-top: 1rem;
    font-size: 1.3rem;
    margin-left: 0.6rem;
    @include flex-direction(column);
    text-transform: capitalize;
    @include justify-content(flex-start);
  }
  .item {
    cursor: pointer;
    margin-top: 0.7rem;
  }
  .myAccount {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
}
.menuHeaderWrapper {
  position: absolute;
  top: 11.3rem;
  width: calc(100vw - 90%);
  height: 85%;
  left: 1.2rem;
  background-color: #fff;
  box-shadow: $shadow-light;
  overflow: auto;
  z-index: 1000;
  @include flexbox;
  @include flex-direction(column);
  padding: 1rem;
  border-radius: 0 0.25rem 0.25rem 0;
  @include transition(width 0.5s);
  margin-top: 0.7rem;
  @include respond(tablet) {
    top: 10rem;
    left: 0rem;
    height: 60%;
  }
  @include respond(phone) {
    left: 0rem;
    width: 50%;
    height: 80%;
    top: 10rem;
  }
}
.menuHeaderWrapperClose {
  width: 0 !important;
  height: 0 !important;
  @include transition(width 0.5s ease-out);
}
.imgWrapper {
  background: $color-iot-green-primary;
  padding: 1rem;
  @include flexbox;
  @include justify-content(flex-start);
}
.logoMenu {
  padding: 1;
  height: 2rem;
}
.headerMenuTitle {
  font-size: 2rem;
  padding: 1rem;
  background-color: $color-login-page-hiway;
  color: #fff;
  text-transform: uppercase;
  @include flexbox;
  border-radius: 0.6rem;
  @include justify-content(flex-start);
  @include align-items(center);
  cursor: pointer;
}
.textTilte {
  margin-right: auto;
  font-weight: bold;
  @include respond(phone) {
    font-size: 1.5rem;
  }
}
.mainLinksWrapper {
  list-style-type: none;
  margin-top: 1rem;
}
.expandListWrapper {
  list-style-type: none;
}
.expanedLink {
  font-size: 1.4rem;
  color: $color-iot-green-primary;
  margin-bottom: 0.3rem;
  padding: 0.5rem;
  border-radius: 0.6rem;
  cursor: pointer;
  font-weight: bold;
  margin-left: 1rem;
  &:hover {
    background: $color-iot-green-primary;
    color: #fff;
  }
  @include respond(phone) {
    font-size: 1.3rem;
  }
}
.singleLink {
  font-size: 1.6rem;
  color: $icon-text-color;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.6rem;
  cursor: pointer;
  &:hover {
    background: $color-login-page-hiway;
    color: $icon-text-color;
  }
  @include respond(phone) {
    font-size: 1.4rem;
  }
}
.activeLink {
  background: $color-login-page-hiway;
  color: $icon-text-color;
}
.listExpand {
  @include flexbox;
  @include flex-direction(column);
  list-style-type: none;
}
.title {
  margin-right: auto;
}

.iconExpand {
  color: $color-iot-green-primary;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
}
.titleExpand {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 1rem;
  @include flexbox;
  cursor: pointer;
  border-radius: 0.6rem;
  color: $color-iot-green-primary;
  &:hover {
    background: $color-iot-green-primary;
    color: #fff;
  }
  @include respond(phone) {
    font-size: 1.4rem;
  }
}
.titleExpand:hover .iconExpand {
  color: #fff;
}
.breakline {
  @include modelBreakLine;
}
.textMenu {
  margin-left: 0.7rem;
  @include respond(tablet) {
    font-size: 1.8rem;
  }
}
