@import "../../../index.scss";
.RotateCars {
  @include flexbox;
  max-height: 4rem;
  height: 4rem;
  // overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  // width: 20rem;
  @include respond(phone) {
    @include flex-direction(column);
    width: unset;
    @include align-items(flex-end);
    position: absolute;
    top: 11rem;
    z-index: 10 !important;
    height: unset;
    max-height: unset;
    margin-left: unset;
  }
}
.btnSave,
.iconRotate,
.countRotation,
.iconSave {
  outline: none;
  border: none;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  font-size: inherit;
  @include mapButton;
  width: 5rem;
  height: 4rem;
  padding: 1rem 1rem;
  margin-left: unset;
  border-radius: unset;
  @include respond(phone) {
    width: 4rem !important;
    // font-size: 1.6rem !important;
    text-align: center;
    height: 4rem;
  }
}
.countRotation {
  height: 4rem;
  background-color: #fff;
  pointer-events: none !important;
  //border-left: 1.5px solid #f2f2f6;

  @include respond(phone) {
    font-size: 1.2rem;
    padding: 0;
  }
}

.iconSave {
  padding-bottom: 0.9rem !important;
  border-right: 1px solid #f2f2f6;

  @include respond(phone) {
    display: block;
    box-sizing: border-box;
    padding: 0.7rem 0;
    margin-left: 0;
    border-radius: 0.2rem;
    border-right: unset;
  }
}
.iconRotate {
  border-radius: 0.2 0rem 0rem 0.2rem !important;
  @include respond(phone) {
    border-radius: 0.2rem;
  }
}
.iconSync {
  color: #fff !important;
}
.IconText {
  @include positionCenter;
  font-size: 1.6rem;
  @include respond(phone){
    font-size: 1.8rem;
  }
}
.numRotate {
  font-size: 1.4rem;
  @include respond(phone) {
    font-size: 1.4rem;
  }
}
.saveIcon {
  font-size: 1.8rem;
}
.alarmBtn {
  width: 12rem;
  margin-right: 1rem;
  @include respond(phone) {
    width: 4rem;
    position: relative;
    margin-right: unset;
    margin-bottom: 1rem;
  }
  &:disabled{
    opacity: .6;
  }
}

.iconAlarmOn,
.iconAlarmOff {
  display: none;
  @include respond(phone) {
    display: inline-block;
    @include positionCenter;
    font-size: 1.6rem;
  }
}
.imageClass {
  width: 3rem;
  @include positionCenter;
}
.iconAlarmOn {
  color: #fff;
}
