@import "../../../index.scss";
.mainWrapper {
  @include flexbox;
  @include flex-direction(column);
  width: 33rem;
  position: relative;
  background: white;
  margin-left: 1rem;
  padding: 2rem;
  @include transition(width 0.25s);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 0.2rem;
  height: 50rem;
}

.listWrapper {
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
}
.itemsWrapper {
  @include flexbox;
  overflow: auto;
  @include flex-wrap(wrap);
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
}
.filterTitle {
  font-size: 1.8rem;
  
}
.mainListWrapper {
  overflow: hidden;
  @include flexbox;
  @include flex-direction(column);
  background: white;
}
.types {
  font-size: 1.4rem;
  margin: 1.2rem;
  padding: 1rem 0;
  border-bottom: 1px solid #e7e7e7;
  @include flexbox;
  @include justify-content(flex-start);
  text-transform: capitalize;
  @include flex(0 0 40%);
  @include flex-direction(column);
  cursor: pointer;
}

.header{
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(center);
  background-color: $color-login-page-hiway;
  color: #fff;
}
.closeIcon {
  margin-left: auto;
  font-size: 1.6rem;
  padding: 0.5rem;
  cursor: pointer;
  padding: 1rem;
}
.title {
  font-size: 1.8rem;
  margin-left: 0.7rem;
}