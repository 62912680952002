@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 40em) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (min-width: 40.06em) and (max-width: 64em) {
      @content;
    }
  }  
  //1400
  @if $breakpoint == tab-port-big {
    @media only screen and (max-width: 87.5em) {
      @content;
    }
  }

  @if $breakpoint == tab-port-small {
    @media only screen and (max-width: 62.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port-height {
    @media only screen and (max-height: 40.62em) {
      @content;
    }
  }
 
}
// input[type='text']:focus,
// input[type='number']:focus,
// textarea:focus {
// 	font-size: 16px !important;
// }

// /* disable auto-zoom on iphone input field focus */
// @media screen and (-webkit-min-device-pixel-ratio:0) {
// 	select:focus,
// 	textarea:focus,
// 	input:focus {
// 		font-size: 16px !important;
// 	}
// }