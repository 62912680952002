.ClusterMarkers {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: -4.5rem;
  margin-left: -2rem;
  z-index: 1 !important;
  .count {
    font-size: 1.6rem;
    position: absolute;    
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 18%; /* Adjust this value to move the positioned div up and down */
    color: #fff;
    //width: 60%; /* Set the width of the positioned div */
  }
  .marker {
    width: 4.5rem;
    height: 4.5rem;
    cursor: pointer;
    position: relative;
  }
  .dotColor {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    top:0%;
    left:65%;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
  }
}
