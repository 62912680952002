@import "../../index.scss";
.ImportFiles {
  @include flexbox;
  .icon {
    margin-right: 0.2rem;
    color: $icon-text-color;
    font-size: 1.8rem;
  }
  .closeIcon {
    top: 0;
    right: 0rem;
    padding: 0.3rem;
    position: absolute;
    color: $icon-text-color;
  }
  .file {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    width:1rem;
    cursor: pointer !important;
    &:focus {
      cursor: pointer !important;
    }
  }
  .label {
    padding: 1rem 1rem;
    background-color: rgb(255, 255, 255);
    @include flexbox;
    border-radius: 0.2rem;
    @include transition(all 0.4s);
    z-index: 1;
    position: relative;
    cursor: pointer !important;
    box-shadow: $shadow-light;
    height: 2rem;
    width: 2rem;
    // margin-top: 1rem;
    // margin-left: 1rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;;
    // @include respond(phone) {
    //   margin-right: 0.5rem;
    // }
    &:hover {
      @include hover;
    }
    &:active {
      @include active;
    }
    &:disabled {
      @include disabled;
      cursor: not-allowed;
    }
  }
  .toolTip{
    @include toolTip
  }

}
.ImportFiles:hover .toolTip{
  visibility: visible;
  font-size: 1.2rem;
  font-weight: 650 !important;
  color: #666666;
  letter-spacing: 1.25px;
  z-index: 1000;
}