$radioSize: 22px;
$radioBorder: #d1d7e3;
$radioActive: #5d9bfb;
@import "../../index.scss";
.radio {
  display: block;
  cursor: pointer;
  input[type="radio"]:disabled + span:after {
    cursor: not-allowed !important;
    opacity: 0.6;
  }
  input {
    display: none;
    & + span {
      line-height: $radioSize;
      height: $radioSize;
      padding-left: $radioSize;
      display: block;
      position: relative;
      &:not(:empty) {
        padding-left: $radioSize + 8;
      }
      
      &:before,
      &:after {
        content: "";
        width: $radioSize;
        height: $radioSize;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;       
      }
      &:before {
        background: $radioBorder;
        -webkit-transition: background 0.2s ease,
          -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
        transition: background 0.2s ease,
          -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
        -o-transition: background 0.2s ease,
          transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
        transition: background 0.2s ease,
          transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
        transition: background 0.2s ease,
          transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2),
          -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
      }
      &:after {
        background: #fff;
        -webkit-transform: scale(0.78);
        -ms-transform: scale(0.78);
        transform: scale(0.78);
        -webkit-transition: -webkit-transform 0.6s
          cubic-bezier(0.175, 0.885, 0.32, 1.4);
        transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        -o-transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4),
          -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        
      }
      
    }

    &:checked + span {
      &:before {
        -webkit-transform: scale(1.04);
        -ms-transform: scale(1.04);
        transform: scale(1.04);
        background: $color-login-page-hiway;
      }
      &:after {
        -webkit-transform: scale(0.4);
        -ms-transform: scale(0.4);
        transform: scale(0.4);
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        -o-transition: transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      }
    }
  }
  &:hover {
    input {
      & + span {
        &:before {
          -webkit-transform: scale(0.92);
          -ms-transform: scale(0.92);
          transform: scale(0.92);
        }
        &:after {
          -webkit-transform: scale(0.74);
          -ms-transform: scale(0.74);
          transform: scale(0.74);
        }
      }
      &:checked + span {
        &:after {
          -webkit-transform: scale(0.4);
          -ms-transform: scale(0.4);
          transform: scale(0.4);
        }
      }
    }
  }
}
