@import "../../../../index.scss";
.historyWrapper {
  overflow: hidden;
  position: relative;
  z-index: 100;
}
.breakLine {
  width: 98%;
  margin-top: 1rem;
  border: 0.5px solid $color-border;
  @include respond(phone) {
    width: unset;
  }
}
.icon {
  font-size: 1.4rem;
  margin: 0.5rem;
  margin-right: 1rem;
  padding: 0.5rem;
  color: black;
  cursor: pointer;
  @include respond(phone) {
    margin: unset;
    margin-right: 3rem;
  }
}
.title {
  @include flex(0 0 66%);
  font-size: 1.5rem;
  margin-top: 1rem;
  color: black;
  text-transform: capitalize;
  @include respond(tab-port-big) {
    font-size: 1.6rem;
  }
}
.wrapperDetails {
  // @include flexbox;
  list-style: none;
  // @include flex-wrap(wrap);
  overflow: hidden;
  padding-right: 1rem;
  padding-top: 1rem;
  height: 100%;
  @include respond(tab-port-height) {
    overflow: auto;
    // max-height:40vh;
  }
}
.titleWrapper {
  @include flexbox;
  flex-direction: row;
  width: 100%;
  @include justify-content(space-between);
  @include align-items(center);
  @include respond(phone) {
    width: 100%;
    margin: 1.5rem 2.5% 1.5rem 2.5%;
  }
}
.tableWrapper {
  width: 100%;
  height: 75%;
  border-top: 0.5px solid $color-border;
  // @include respond(tab-port-height) {
  //   max-height: 40vh;
  // }
  @include respond(phone) {
    overflow: auto;
    height: 70%;
    max-height: 70%;
  }
}

.table {
  @include flexbox;
  overflow: auto;
  @include flex-direction(column);
  position: relative;
  min-height: 20rem;

  @include respond(phone) {
    overflow: unset;
  }
}
.tableRow {
  @include tableRow;
  text-transform: capitalize;
  font-weight: unset;
  cursor: pointer;
  @include respond(phone) {
    @include flex-wrap(nowrap);
    width: 180%;
  }
}

.gpsTooltip:hover .toolTip {
  visibility: visible;
  font-size: 1.4rem;
  color: $icon-text-color;
}
.toolTip {
  @include toolTip;
}
.dateTooltip:hover .toolTip {
  visibility: visible;
  font-size: 1.4rem;
  color: $icon-text-color;
}
.subTitle {
  font-size: 1.1rem;
  text-transform: lowercase;
}
.textAlertCell {
  @include textCell;
  @include flexbox;
  @include flex-direction(column);
  font-size: 1.4rem;
  white-space: unset;
  @include flex(0 0 11%);
  &:first-child {
    @include flex(0 0 5%);
  }

  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.textAlertCell:hover .toolTip {
  visibility: visible;
}
.textCell {
  @include textCell;
  font-size: 1.4rem;
  @include flex(0 0 12%);
  &:first-child {
    @include flex(0 0 5%);
  }
  &:nth-child(2) {
    @include flex(0 0 15%);
  }

  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.textCellSos {
  @include textCell;
  font-size: 1.4rem;
  @include flex(0 0 15.5%);
  &:first-child {
    @include flex(0 0 5%);
  }

  &:nth-child(6) {
    @include flex(0 0 8%);
  }
  &:nth-child(7) {
    @include flex(0 0 8%);
  }
  &:nth-child(8) {
    @include flex(0 0 10%);
  }
  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.chatText {
  @include textCell;
  font-size: 1.4rem;
  @include flex(0 0 15%);
  &:first-child {
    @include flex(0 0 5%);
  }
  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.textCellAlert {
  @include textCell;
  font-size: 1.4rem;
  @include flex(0 0 9.5%);
  &:first-child {
    @include flex(0 0 5%);
  }
  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.pagination {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-start);
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  @include respond(phone) {
    width: 96%;
  }
}
.spinnerWrapper {
  @include positionCenter;
}
.plus {
  color: $color-login-page-hiway;
}
.noDataRow {
  @include tableRow;
  @include justify-content(center);
  text-transform: capitalize;
  cursor: pointer;
  @include respond(phone) {
    @include flex-wrap(nowrap);
    @include justify-content(flex-start);
  }
}
.filterWrapper {
  @include flexbox;
  @include justify-content(flex-start);
  margin-top: 1rem;
  @include align-items(center);
}
.inputWrapper,
.keepAliveWrapper {
  @include flex(0 0 33%);
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(flex-start);
  &:nth-child(3) {
    @include flex(0 0 5%);
    margin-left: 0.5rem;
  }
  &:nth-child(even) {
    margin-left: 0.5rem;
  }
  @include respond(phone) {
    @include flex(unset);
    margin-right: unset !important;
    margin-bottom: 1rem;
    height: 6rem;
  }
}
.keepAliveWrapper {
  @include flex(0 0 25% !important);
}
.dateWrapper {
  @include flexbox;
  // width: 70%;
  @include align-items(center);
  @include respond(phone) {
    display: none;
  }
}
.btn {
  background-color: $color-input-font;
  padding: 0.375rem 0.75rem;
  height: 4.5rem;
  font-size: 1.3rem;
  color: #fff;
  border: none;
  margin-left: 1rem;
  border-radius: 0.6rem;
  margin-top: 0.6rem;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    @include hover;
  }
  &:disabled {
    @include disabled;
  }
  &:active {
    @include active;
  }
  @include respond(phone) {
    height: 3.5rem;
  }
}
.customMenuWrapper {
  @include flexbox;
  margin-left: auto;
  margin-right: 1rem;
  margin-top: 0.6rem;
  @include align-items(center);
  @include respond(phone) {
    margin-right: 3rem;
  }
}
.msgDisplayWrapper {
  @include positionCenter;
  background-color: #fff;
  width: 30rem;
  height: 20rem;
  overflow: auto;
  padding: 1rem;
  box-shadow: $shadow-light;
  z-index: 100;
  top: 25%;
  @include flexbox;
  @include flex-direction(column);
  border-radius: 0.3rem;
  text-transform: none;
}
.topMsg {
  @include flexbox;
  font-size: 1.5rem;
  @include align-items(center);
  @include justify-content(flex-start);
}
.magDisplay {
  font-size: 1.4rem;
  padding: 0.5rem;
  overflow: auto;
}
.contralsMobile {
  display: none;
  @include respond(phone) {
    @include flexbox;
    @include justify-content(flex-start);
    display: block;
  }
}
.checkBoxTelemetryWrapper {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  margin-right: 1rem;
}

.filterCloseIconFilter {
  @include positionCenter;
  top: 10%;
  left: 94%;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
}
.filterCloseIconFilter:hover .toolTip {
  visibility: visible;
}
.dateWrapperMobile {
  @include positionCenter;
  top: 27%;
  width: 85%;
  left: 39%;
  background-color: #fff;
  box-shadow: $shadow-light;
  @include flexbox;
  @include flex-direction(column);
  z-index: 11;
  padding: 1rem;
  margin: 0 4.5rem;
  height: 34%;
  border-radius: 0.6rem;
  @include respond(phone) {
    overflow: auto;
    height: 45%;
  }
}

.datesInputMobileWrapper {
  @include flexbox;
  @include flex-direction(column);
  margin-top: 3rem !important;
  margin-bottom: auto;
}
.btnMobileWrapper {
  @include flexbox;
  @include justify-content(flex-end);
  margin-bottom: 1rem;
}
.keepAliveMsg {
  font-size: 1.4rem;
  word-wrap: break-word;
  padding: 2rem;
}
