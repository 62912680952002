@import "../../index.scss";

.VersionUpdates {
  background-color: #fff;
  @include positionCenter;
  left: 74%;
  padding: 2rem 2rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  box-shadow: $shadow-light;
  height: 90%;
  width: 50%;
  @include respond(phone) {
    width: 100%;
    height: 100%;
    left: 50%;
    @include flexbox;
    @include flex-direction(column);
  }
  .header {
    @include modelHeader;
    padding: 1rem;
  }
  .breakLine {
    @include modelBreakLine;
    @include respond(phone) {
      margin: 0 1rem;
    }
  }
  .counterWrapper {
    margin: auto;
  }
  .time {
    font-size: 2rem;
    color: black;
  }
  .msg {
    margin: 1rem 0;
    font-size: 1.4rem;

    @include respond(phone) {
      margin-left: 1.5rem;
      line-height: 2;
      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(flex-start);
    }
  }
}
