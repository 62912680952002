@import "../../index.scss";

.CreateVehicles {
  padding: 1rem 1rem 0 1rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  @include respond(tablet) {
    top: 25%;
  }
  @include respond(phone) {
    height: 100%;
    padding: 2rem;
    padding-top: 1rem;
    @include align-items(center);
  }
  .header {
    @include modelHeader;
    @include flexbox;
    margin-right: auto;
  }
  .text {
    margin-right: auto;
  }
  .breakLine {
    @include modelBreakLine;
    @include respond(phone) {
      width: 96%;
    }
  }
  // .bottomLine {
  //   @include flexbox;
  //   width: 100%;
  //   @include flex-wrap(wrap);
  //   @include justify-content(space-between);
  //   @include align-items(center);
  // }
  .infoWrapper {
    @include flexbox;
    @include justify-content(space-between);
    background-color: #fff;
    padding: 1rem 1rem 0 1rem;
    flex-direction: row;
    max-height: 75%;
    height: 75%;
    @include respond(phone) {
      padding: 0 1rem;
      @include justify-content(unset);
      width: 100%;
      max-height: 65%;
      //height: 60%;
    }
  }
  .formWrapper {
    @include flexbox;
    @include flex-direction(column);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 1rem;
    @include respond(tablet) {
      max-height: calc(60vh - 4rem);
    }
    @include respond(phone) {
      width: 100%;
      overflow: auto;
      overflow-x: hidden;
      //max-height: calc(100vh - 30rem);
      margin: unset;
    }
  }
  .form {
    @include flex-wrap(wrap);
    @include flexbox;
    margin-right: auto;
    @include justify-content(space-between);
    height: 70%;
    overflow: auto;
    @include respond(phone) {
      margin-right: unset;
      display: unset;
      overflow-x: hidden;
      padding: 0 1rem;
      height: 100%;
    }
  }
  .iconSearch {
    position: absolute !important;
    right: 5%;
    top: 50%;
    font-size: 1.6rem;
    color: $color-iot-green-primary;
  }

  .inputWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(flex-start);
    &:nth-child(even) {
      margin-right: 0.5rem;
    }
    @include respond(phone) {
      @include flex(unset);
      margin-right: unset !important;
      margin-bottom: 1rem;
    }
  }
  .selectWithOptionsWrapper {
    position: relative;
    display: inline-block;
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(flex-start);
    @include respond(phone) {
      @include flex(unset);
    }
  }

  .label {
    font-size: 1.6rem;
    text-transform: capitalize;
  }
  .error {
    @include globalErrorMsg;
  }
  .stickError {
    @include globalErrorMsg;
    margin-left: 2rem;
    margin-bottom: 0.3rem;
    @include respond(phone) {
      margin-right: auto;
    }
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin-bottom: 1rem;
    @include respond(phone) {
      margin-top: unset;
      margin-top: 1rem !important;
      margin-left: auto;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .listWrapper {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;
    @include flex(0 0 25%);
    padding: 0.5rem;
    position: relative;
  }
  .clearSelectTerminal {
    position: absolute;
    right: 0;
  }
  .itemsWrapper {
    overflow: auto;
    @include flexbox;
    @include flex-direction(column);
    padding-right: 0.5rem;
    @include respond(tablet) {
      max-height: 45vh;
    }
    @include respond(phone) {
      max-height: 30vh;
    }
    margin-top: 0.5rem;
  }
  .item {
    font-size: 1.2rem;
    padding: 1rem;
    background-color: #fafafa;
    border: 1px solid #e7e7e7;
    @include flexbox;
    @include flex-direction(column);
    cursor: pointer;
    &:hover {
      background-color: #e1e1e1;
    }
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
  .selected {
    z-index: 50;
    color: #fff;
    background-color: $color-iot-green-primary;
    &:hover {
      background-color: $color-iot-green-primary;
    }
  }
  .index {
    margin-right: 1rem;
  }
  .dataWrapper {
    @include flexbox;
  }
  .dataLabel {
    font-size: 1.4rem;
    margin-right: 0.5rem;
  }
  .titleBreakLine {
    @include modelBreakLine;
    margin: 1rem 0;
    position: relative;
    @include respond(phone) {
      display: none;
    }
  }
  .formTitle {
    font-size: 1.7rem;
    color: black;
    text-transform: capitalize;
  }
  .uploadFileWrapper {
    @include flex(0 0 45%);
    position: relative;
    @include flex-direction(column);
    @include flexbox;
    @include justify-content(flex-start);
    margin-right: 0.5rem;
    margin-top: 2.8rem;
    overflow: hidden;
    @include respond(phone) {
      margin-top: 0.3rem;
      margin-bottom: 1rem;
      @include flex(unset);
      overflow: unset;
      margin-right: 0;
    }
  }
  .titleWrapper {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @include respond(phone) {
      display: none;
    }
  }
  .openWrapper {
    @include flexbox;
    @include flex-direction(column);
    position: absolute;
    height: 86%;
    width: 91%;
    z-index: 11;
    transition: width 0.5s;
    visibility: visible;
    background-color: #fff !important;
    margin-top: 0rem;
    margin-bottom: 2rem;
    overflow: auto;
    @include respond(phone) {
      top: 1rem;
      right: 0;
      left: 0;
      width: 96%;
      padding: 0 1rem;
      height: 100%;
    }
  }
  .closeWrapper {
    visibility: hidden;
    height: 0;
    width: 0;
    @include transition(width 0.5s);
    z-index: -1;
  }
  .previewWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    flex-direction: row;
    @include justify-content(flex-start);
    margin-top: auto;

    @include respond(phone) {
      flex: unset;
      // margin-top: 2rem;
    }
  }
  .titleSpeed {
    font-size: $input-label-font-size;
    @include flexbox;
    margin-bottom: auto;
    text-transform: capitalize;
    padding-bottom: 0.8rem;
    @include align-items(center);
    @include respond(phone) {
      padding-bottom: 0.4rem;
      @include flexbox;
    }
  }
  .unitErr {
    font-size: 1.1rem;
    color: $color-red-danger;
  }
  .radioWrapper {
    position: absolute;
    top: 50%;
    @include flexbox;
    margin-bottom: 1rem;

    font-size: 1.2rem;
    align-items: center;
    @include justify-content(flex-start);
    @include respond(phone) {
      @include flex(unset);
      position: relative;
      @include justify-content(space-around);
    }
  }
  .radio {
    margin-left: 4rem;
    margin-right: 1rem;
    @include respond(phone) {
      margin-left: unset;
    }
  }
  .zoneTitleWrapper {
    @include flexbox;
    @include justify-content(space-between);

  }
  .selectAllZoneCheckbox {
    position: relative;
   
  }

  .infoWrapperZones {
    border: solid 1px $color-border;
    border-radius: 0.6rem;
    box-shadow: $shadow-light;
    padding: 1rem;
    position: absolute;
    background-color: #fff;
    @include positionCenter;
    top: 31%;
    left: 50%;
    transform: translate(-55%, -40%);
    width: 20rem;
    z-index: 11;

  }
  .infoIcon {
    cursor: pointer;
    font-size: 1.2rem;
    color: $color-login-page-hiway;
    margin-right: 0.5rem;
  }
  .infoHeader {
    @include flexbox;
    @include justify-content(space-between);
    margin-bottom: 0.5rem;
  }
  .infoDataWrapper {
    @include flexbox;
    @include flex-direction(column);
    overflow-y: auto;
  } 
  .listWrapperZones {
    @include flexbox;
    height:15rem;
    padding-top:1rem;
    overflow: auto;
  }
  .listItem {
    font-size: 1.2rem;
    color: black;
    list-style: disc;
    margin-left: 2rem;
    text-align: start;
    font-weight: 400;
  }
  .infoTitle {
    font-size: 1.4rem;
    font-weight: 400;
    color: black;
  } 
  .closeIcon{
    cursor: pointer;
  }
  .toolTip {
    @include toolTip;
  }  
  .closeIcon:hover .toolTip {
    visibility: visible;
  }
}
