@import "../../index.scss";

.searchByTypes {
  @include flexbox;
  @include justify-content(flex-start);
  @include flex-direction(column);
}
.titleSearch {
  font-size: $input-label-font-size;
  padding-bottom: 0.8rem;
  @include respond(phone) {
    padding-bottom: 0.4rem;
  }
}
.error {
  @include globalErrorMsg;
}
.searchWrapper {
  @include flexbox;

  .input {
    padding: 0.375rem 0.75rem;
    line-height: 3;
    font-size: 1.2rem;
    color: $color-input-font !important;
    background-clip: padding-box;
    border: 1px solid $color-input-border;
    border-radius: 0.25rem;
    @include flex(0 0 80%);
    &::-webkit-input-placeholder {
      text-transform: capitalize;
      font-size: 1.2rem;
    }
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
    &:focus {
      outline: none;
    }
  }
  .iconSearch {
    @include flexbox;
    font-size: 1.6rem;
    background: $icon-text-color;
    color: #fff;
    cursor: pointer;
    @include justify-content(center);
    @include align-items(center);
    overflow: hidden;
    @include flex(0 0 15%);
  }
  .clear {
    @include flexbox;
    @include align-items(center);
    cursor: pointer;
    font-size: 1.6rem;
    width: 2rem;
    margin-left: -2rem;
    color: $color-sub-title;
    z-index: 2;
  }
}
