@import "../../../index.scss";
.custommarker {
  position: absolute;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.vinNumber {
  background-color: $color-login-page-hiway;
  color: #fff;
  padding: 0.5rem;
  @include flexbox;
  font-size: 1.3rem;
  @include justify-content(center);
  @include align-items(center);
  width: 9.8rem;
  margin-top: 4rem;
  //margin-left: 2rem;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.imgNotification {
  position: absolute;
  bottom: .5rem;
  transform: translate(0, 0);
}
.animation {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-3;
  animation-timing-function: ease;
}
@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
.imageStyles {
  height: 6.7rem;
  width: 3.5rem;
  position: relative;
  //top: -3.8rem;
}
.imageStylesMin {
  height: 6.7rem;
  width: 2.9rem;
  position: relative;
}
.imageStylesMidRage {
  height: 6.7rem;
  width: 3.2rem;
  position: relative;
}
.vinText {
  margin-right: auto;
}
.dotIcon {
  color: $color-input-font;
  cursor: pointer;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 1.3rem;
}
.backgroundColorAlertZoneOff {
  opacity: 0.8;
  position: absolute;
  border-radius: 40%;
  background-color: #f20000;
  filter: blur(1rem);
  width: 5rem;
  height: 8rem;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); //added
}
.backgroundColorAlertZoneOn {
  position: absolute;
  border-radius: 40%;
  width: 5rem;
  height: 8rem;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); //added
  animation: glow linear 1.8s infinite;
}
@keyframes glow {
  0% {
    background-color: #f20000;
    filter: blur(1rem);
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #f20000;
    filter: blur(1rem);
  }
}
