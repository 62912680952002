@import "../../index.scss";
.tabWrapper {
  margin-bottom: 2rem;
  @include respond(phone){
    margin-bottom: .7rem;
  }
}
.tab {
  width: 100%;
  padding: 1rem;
  font-family: inherit;
}

ul.inline {
  list-style: none;

  li {
    display: inline-block;
    margin-right: 0.2;
    padding: 1rem;
    border-bottom: 3px solid white;
    @include transition(all 0.5s);
    font-family: inherit;
    font-size: $input-label-font-size;
    cursor: pointer;
    color:$color-input-font;
    font-weight: $input-font-weight;
    
    &.selected {
      border-bottom: 3px solid $color-form-button-primary;
      @include respond(tab-port-big) {
        border-bottom: 1.5px solid $color-form-button-primary;
      }
      color: $color-input-font;
    }
    @include respond(max-height-model) {
      padding: .8rem;
    }
  }
  .textTab{
    @include flexbox;
    @include justify-content(flex-start);
    &:first-child{
      margin-right: .2rem
    }
    @include respond(phone){
      @include flex-direction(column);
    }
    
  }
}
