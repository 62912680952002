@import "../../index.scss";
.amountText {
  margin-right: auto;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.btnWrapper {
  @include flexbox;
  position: relative;
  width: 20rem;
  @include justify-content(flex-end);
  @include align-items(center);
  flex-direction: row;
  height: 4.5rem;
  @include respond(tab-port-big) {
    height: 4rem;
  }
  .inactivePages {
    outline: none;
    border: 1px solid #eaecee;
    color: #b5b0b9;
    text-transform: capitalize;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    @include flex(0 0 20%);
    height: 100%;
    @include justify-content(center);
    @include align-items(center);
    margin: 0 0.2rem;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .prev::before {
    font-family: "Font Awesome 5 Pro";
    text-align: center;
    font-size: 1.1rem;
  }
  .next::before {
    font-family: "Font Awesome 5 Pro";

    text-align: center;
    font-size: 1.1rem;
  }
  .navigation {
    outline: none;
    border: 1px solid #eaecee;
    color: #b5b0b9;
    text-transform: capitalize;
    cursor: pointer;
    background-color: #fff;
    @include flexbox;
    @include flex(0 0 20%);
    height: 100%;
    @include justify-content(center);
    @include align-items(center);
    font-size: 1.4rem;
    margin: 0 0.2rem;
  }
  .pageNumber {
    border: 1px solid black;
  }
  .activePage {
    outline: none;
    border: 1px solid #eaecee;
    color: #fff;
    text-transform: capitalize;
    cursor: pointer;
    background-color: $icon-text-color;
    @include flexbox;
    @include flex(0 0 20%);
    height: 100%;
    @include justify-content(center);
    @include align-items(center);
    margin: 0 0.2rem;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.titleCl {
  @include flex(0 0 10%);
}
.radioWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
