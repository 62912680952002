@import "../../index.scss";

.SelectWithIcons {
  @include flex(0 0 45%);
  @include flexbox;
  @include flex-direction(column);
  position: relative;
  @include justify-content(flex-start);
  margin-right: 0.5rem;
  font-family: inherit;
  @include respond(phone) {
    flex: unset;
    margin-right: unset;
    margin-bottom: 1rem;
  }
  .mainWrapper {
    padding: 0.375rem 0.75rem;
    line-height: 3;
    font-size: 1.2rem;
    color: $color-input-font !important;
    background-clip: padding-box;
    border: 1px solid $color-input-border;
    text-transform: capitalize;
    cursor: pointer;
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
    &:active,
    &:focus {
      border: 1px solid $color-form-button-primary;
    }
  }
  .wrapperSelected {
    position: absolute;
  }
  .itemsList {
    visibility: hidden;
    position: absolute;
    background-color: #fff;
    width: 99%;
    border: 1px solid $color-input-border;
    z-index: 1;
    top: 7rem;
    @include respond(tab-port-big) {
      top: 6.4rem;
    }
    left: 0;
    max-height: 20vh;
    min-height: 10vh;
    overflow-y: auto;
  }
  .iconArrow {
    font-size: $input-label-font-size;
    position: absolute;
    right: 0;
    font-family: inherit;
    padding-right: 1.4rem;
  }
  .icon {
    font-size: 1.6rem;
    color: $color-form-button-primary;
    margin-right: 1rem;
    position: absolute;
    text-align: center;
  }
  .itemsList li {
    @include flexbox;
    padding: 0rem 1rem;
    text-transform: capitalize;
    &:not(:last-child) {
      border-bottom: 1px solid $color-input-border;
    }
    &:active,
    &:focus,
    &:hover {
      // background-color: $color-form-button-primary;
      // color: #fff;
      background-color: $color-login-page-hiway;
      color: #fff;
    }
  }

  .selected {
    background-color: $color-form-button-primary;
  }
  .showList {
    visibility: visible;
  }

  .error {
    font-size: 1.1rem;
    margin-top: 0.3rem;
    color: $color-red-danger;
  }
  .label {
    font-size: $input-label-font-size;
    text-transform: capitalize;
    position: relative;
    padding-bottom: 0.8rem;
    @include flexbox;
    @include justify-content(flex-start);
    @include respond(phone) {
      padding-bottom: 0.4rem;
    }
  }
  .showmenu {
    display: none;
  }
  .text {
    font-size: $input-label-font-size;
    text-transform: capitalize;
    margin-right: auto;
  }
  .iconLabel {
    margin-left: 3rem;
    padding-top: 0.4rem;
    position: relative;
    top: 0.3rem;
    &:first-child {
      margin-left: unset;
    }
  }
}
