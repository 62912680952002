@import "../../index.scss";
.btn {
  border-radius: 0.25rem;
  outline: none;
  border: none;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.4rem;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  display: inline-block;
  line-height: 3;
  text-transform: capitalize;
  font-size: 1.2rem;
  min-width: 10rem;
  font-family: inherit;
  background-color: $icon-text-color;
  color: #fff;
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
  &:disabled {
    @include disabled;
  }
  &:hover {
    @include hover;
  }
}
