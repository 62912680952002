@import "./media-query.scss";
@mixin positionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@mixin globalErrorMsg {
  font-size: 1.1rem;
  color: $color-red-danger;
}
@mixin toolTip {
  padding: 1rem;
  position: absolute;
  width: auto;
  white-space: nowrap;
  word-wrap: no-wrap;
  -webkit-box-shadow: $shadow-light;
          box-shadow: $shadow-light;
  border-radius: 0.5rem;
  background-color: #fff;
  top: 4rem;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  z-index: 2;
  text-transform: capitalize;
  font-size: 1.2rem;
  visibility: hidden;
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;

    -webkit-transform: rotate(45deg) translateX(-50%);

        -ms-transform: rotate(45deg) translateX(-50%);

            transform: rotate(45deg) translateX(-50%);
    background-color: #fff;
    left: 50%;
    top: -0.1rem;
    z-index: 400;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: rotate(45deg) translateX(-50%) translateZ(-1px);
            transform: rotate(45deg) translateX(-50%) translateZ(-1px);
    background-color: #fff;
    left: 50%;
    top: 0rem;
    z-index: -1;
    -webkit-box-shadow: $shadow-light;
            box-shadow: $shadow-light;
    z-index: 1;
  }
}
@mixin modelHeader {
  font-size: 2rem;
  margin: 1rem 0 0 2rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
  @include respond(phone){
    margin-left: 1rem;
  }
  .headerIcon {
    font-size: 2rem;
    margin-right: 1rem;
    color: $icon-text-color;
  }
}
@mixin modelBreakLine {
  border: 0.5px solid $color-border;
}

// table
@mixin tableRow {
  @include flexbox;
  flex-grow: 0;
  -webkit-flex-grow: 0;
  @include flex-wrap(wrap);
  padding: 1rem 1.5rem;
  font-size: $input-tableData-font-size;
  list-style: none;
  position: relative;
  align-items: center;
  border-bottom: 0.5px solid $color-border;
  border-right: 0.5px solid $color-border;
  border-left: 0.5px solid $color-border;
  @include respond(phone) {
    font-size: 1.2rem;
    padding: 1rem 0.5rem;
    width: 120%;
  }
}
@mixin tableRowHover {
  &:nth-child(odd):hover {
    background-color: $color-border;
  }
  &:nth-child(even):hover {
    background-color: #f2f2f1;
  }
}
@mixin textCell {
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  padding-left: 0.5rem;
  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
  @include respond(phone) {
    padding-left: unset;
  }
}
@mixin tableWrapper {
  @include flexbox;
  width: 100%;
  margin: 0 2rem;
  @include flex-direction(column);
  border-radius: .8rem;
  box-shadow: $shadow-light;
  background-color: #ffffff !important;
}
/** transition */
@mixin slideTransition {
  -webkit-transition: width 0.2s;
  -moz-transition: width 0.7s;
  -ms-transition: width 0.7s;
  -o-transition: width 0.7s;
  transition: width 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  width: 0;
}
@mixin slideTransitionOpen {
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 100%;
}
@mixin inputWrapper {
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(flex-start);
}
@mixin webkitflex {
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex;
}
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -ms-flex: $values;
  -webkit-flex: $values;
  flex: $values;
}
@mixin flex-direction($direction: row) {
  @if $direction == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $direction == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $direction == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}
@mixin flex-wrap($value) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}
@mixin justify-content($value) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}
@mixin align-items($value) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}
@mixin transition($value) {
  -webkit-transition: $value;
  -o-transition: $value;
  transition: $value;
}
@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value;
  transform: $value;
}
@mixin hover {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
}
@mixin disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
@mixin active {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
}
@mixin mapButton {
  height: 5.5rem;
  z-index: 900;
  //margin-left: 1rem;
  outline: none;
  border: none;
  padding: 0.5rem 1rem 1rem 1rem;
  border-radius: 0.2rem;
  color: #666666;
  font-size: 1.2rem;
  font-weight: 650;
  width: 20rem;
  line-height: 2.4rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;
  //margin-top: 1rem;
  @include align-items(center);
  @include respond(phone) {
    //margin-left: 0.5rem;
    width: unset;
  }
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: none rgb(255, 255, 255);
  &:hover {
    @include hover;
  }
  &:disabled {
    @include disabled;
  }
  &:active {
    @include active;
  }
}
