@import "../../index.scss";
.PreviewImages {
  background-color: white;
  z-index: 10;
  @include flexbox;
  @include flex-direction(column);
  width: fit-content;
  height: 70%;
  overflow: auto;
  .imageWrapper {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }
}
.btnwrapper {
  @include flexbox;
  @include justify-content(flex-end);
  margin-top: 2rem;
}
.titleWrapper {
  @include flexbox;
  @include justify-content(space-between);
  margin-bottom: 1rem;
}
.title {
  font-size: 1.7rem;
  color: black;
  text-transform: capitalize;
}
.rightTitle {
  font-size: 1.7rem;
  color: $color-iot-green-primary;
  text-transform: capitalize;
  cursor: pointer;
}
