@import "../../../index.scss";
.UserChats {
  padding: 2rem;
  position: relative;
  @include respond(phone) {
    padding-top: 0;
  }
}
.titleWrapper {
  @include flexbox;
  padding: 0 1rem;
  @include align-items(center);
  color: #fff;
  background-color: $color-login-page-hiway;
  @include justify-content(space-between);
  @include respond(phone) {
    width: 96%;
    margin-top: 2rem;
  }
}
.icon {
  font-size: 1.6rem;
  margin: 0.5rem;
  margin-right: 1rem;
  padding: 0.5rem;
  color: #fff;
  cursor: pointer;
  @include flexbox;
  @include align-items(center);
}
.extermalId {
  margin-right: 0.5rem;
  font-weight: bold;
  color: black;
}
.title {
  font-size: 2rem;
  padding: 1rem;
  color: #fff;
  @include flexbox;
  @include align-items(center);
  text-transform: uppercase;
  @include respond(tab-port-big) {
    font-size: 1.6rem;
  }
  @include respond(phone) {
    font-size: 1.8rem;
  }
}
.imgAvatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
}
.iconCloseAvatar {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.4rem;
  cursor: pointer;
}
.fullAvatarImg {
  @include respond(phone) {
    min-height: 100%;
    min-width: 100%;
  }
}
.avatarPreviewOpen {
  @include positionCenter;
  background-color: #fff;
  top: 33%;
  z-index: 1000;
  padding: 1rem;
  box-shadow: $shadow-light;
  width: 55rem;
  height: auto;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include transition(width 0.3s);
  visibility: visible;
  @include respond(phone) {
    width: 85%;
  }
}
.avatarPreviewclose {
  width: 0rem;
  @include transition(width 0.3s);
  visibility: hidden;
  height: 0;
}
.iconChat {
  margin-right: 0.5rem;
  @include flexbox;
  @include align-items(center);
}
.dateDivider {
  @include flexbox;
  @include justify-content(center);
}
.imgIconChatWhite{
width: 2rem;
}
.date {
  background-color: $color-border;
  color: black;
  padding: 0.5rem 2rem;
  border-radius: 0.6rem;
  margin-top: 0.5rem;
  font-weight: bold;
}
.breakLine {
  width: 98%;
  margin-top: 1rem;
  border: 0.5px solid $color-border;
}
.iconRetry {
  cursor: pointer;
  position: absolute;
  opacity: 1 !important;
  color: #d9d9d9;
  border: none;
  background-color: #fff;
  z-index: 100000;
  @include flexbox;
  @include flex-direction(column);
  left: -4rem;
  &:hover {
    box-shadow: $shadow-light;
  }
  @include respond(phone) {
    background-color: #fff;
    left: -5rem;
  }
}
.iconRetry img {
  &:hover {
    box-shadow: $shadow-light;
  }
}
.textretry {
  color: #d9d9d9;
  background-color: transparent;
  font-weight: bold;
}
.container {
  width: 100%;
  margin: 0 auto;
  max-height: calc(100vh - 34rem);
  overflow: auto;
  position: relative;
  @include respond(phone) {
    width: 100%;
    max-height: unset;
    height: calc(100vh - 38rem);
  }
}
.msg {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;

  .bubble {
    float: left;
    max-width: 75%;
    width: auto;
    height: auto;
    @include flexbox;
    @include align-items(center);
    background: #dadada;
    color: black;
    border-radius: 0.5rem;
    position: relative;
    margin: 1rem 0 0.3rem 2.5rem;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
    @include respond(phone) {
      max-width: 80%;
    }

    &.alt {
      margin: 1rem 2.5rem 0.3rem 0px;
      background: $color-login-page-hiway;
      opacity: 1;
      float: right;
    }
    &.follow {
      margin: 0.2rem 0 0.3rem 2.5rem;
    }

    &.altfollow {
      margin: 0.2rem 2.5rem 0.3rem 0px;
      background: #dcf8c6;
      float: right;
    }
    .txt {
      padding: 0.8rem 0 0.8rem 0;
      width: 100%;
      @include flexbox;
      min-width: 8rem;
      word-break: break-word;
      @include flex-direction(column);
      .name {
        font-weight: 600;
        font-size: 1.4rem;
        display: inline-table;
        padding: 0 0 0 1.5rem;
        margin: 0 0 0.4rem 0;
        color: #3498db;
        span {
          font-weight: normal;
          color: #b3b3b3;
          overflow: hidden;
        }
        &.alt {
          color: #2ecc51;
        }
      }
      .message {
        font-size: $chat-body-font-size;
        font-weight: 500;
        padding: 0 1.5rem 0 1.5rem;
        word-break: break-all;
        color: #2b2b2b;
        display: table;
        white-space: pre-wrap;///added
      }
      .timestamp {
        font-size: $chat-time-stamp-font-size;
        padding: 0 1rem;
        @include flexbox;
        @include justify-content(flex-start);
        @include align-items(center);
        position: relative;
        text-transform: uppercase;
        color: #999;
        margin-left: 0.2rem; ///added
      }
    }

    .bubbleArrow {
      position: absolute;
      float: left;
      left: -1.1rem;
      top: 0px;
      &.altfailed {
        bottom: 2rem;
        left: auto;
        right: 4px;
        float: right;
      }
      &.alt {
        bottom: 2rem;
        left: auto;
        right: 4px;
        float: right;
      }
    }

    .bubbleArrow:after {
      content: "";
      position: absolute;
      border-top: 1.5rem solid #dadada;
      border-left: 1.5rem solid transparent;
      border-radius: 4px 0 0 0px;
      width: 0;
      height: 0;
    }
    .bubbleArrow.alt:after {
      border-top: 1.5rem solid $color-login-page-hiway;
      transform: scaleX(-1);
    }
    .bubbleArrow.altfailed::after {
      border-top: 1.5rem solid #ffcc73;
      transform: scaleX(-1);
    }
  }
}
.spinnerLoader {
  @include positionCenter;
  top: 15%;
  z-index: 10;
  font-size: 3rem;
  color: $color-login-page-hiway;
}
.statusIcon {
  color: #999;
  margin-left: auto;
}
.wInputContainer {
  // width: 40rem;
  width: 95%;
  min-height: 2rem;
  box-sizing: border-box;
  padding: 0.9rem 1.8rem 1.1rem;
  // margin-right: 0;
  background-color: #dadada;
  border-radius: 1rem;
  border: 1px solid $color-border;
  // margin-bottom: 2rem;
  display: inline-block;
  min-height: 8rem;
  position: absolute;
  // left: 3rem;
  @include flexbox;
  @include align-items(center);
  z-index: 100;
  bottom: 0rem;
  // margin-left: 0.5rem;
  margin: auto 1rem 2rem 1rem;
  @include respond(phone) {
    width: 95vw;
    margin: unset;
    min-height: 10rem;
    left: 1rem;
    margin-bottom: 10rem;
  }
}

.sendMsgBtn {
  width: 3rem;
  height: 3rem;
  color: #fff;
  margin-left: auto;
  cursor: pointer;
}

.wInputText {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  background-color: #fff;
  min-height: 3rem;
  padding: 0.3rem;
  outline: 0;
  width: 85%;
  resize: none;
  border: none;
  font-size: $chat-body-font-size;
  border-radius: 1rem;
  font-family: inherit;
  position: relative;
  z-index: 1000;
}
textarea::placeholder {
  color: #b3b3b3;
}
.spanOverUsage {
  background-color: $color-login-page-hiway;
}

.msgLength {
  position: absolute;
  bottom: 0;
}
.downArrow {
  padding: 1rem;
  border-radius: 50%;
  font-size: 1.4rem;
  position: absolute;
  bottom: 0;
  right: 1.5rem;
  cursor: pointer;
  background-color: $color-border;
}
.retryMsg {
  width: 100%;
  height: 100%;
  @include positionCenter;
  opacity: 1 !important;
  z-index: 100;
  color: #000;
  @include flexbox;
  font-size: 1.4rem;
  @include align-items(center);
  @include justify-content(center);
  cursor: pointer;
  font-weight: bold;
}
.totalCount {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  position: absolute;
  width: 5rem;
  height: 5rem;
  font-size: 1.3rem;
  z-index: 1000;
  left: -2%;
  top: -37%;
  background-color: #dadada;
  border-radius: 50%;
}
.imgkeepalive {
  height: 2rem;
}
.msCount {
  position: absolute;
  top: 0;
  left: 4.5rem;
  color: #000;
  font-weight: bold;
}
.glow {
  animation: glow linear 2s infinite;
}
@keyframes glow {
  0% {
    background-color: $color-red-danger;
  }
  50% {
    background-color: #dadada;
  }
  100% {
    background-color: $color-red-danger;
  }
}
