@import "../../index.scss";
.Group {
  padding: 2rem 2rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  @include respond(phone) {
    padding: 0 0;
  }
  .info {
    padding: 2rem 2rem;
    @include flex-direction(column);
    @include flexbox;
    height: 100%;
    @include respond(phone) {
      padding: 1rem;
    }
  }
  .header {
    @include modelHeader;
    margin-right: 1rem;
    @include flexbox;
  }
  .dataWrapper {
    @include flexbox;
    @include flex-direction(column);
    padding: 1rem;
    @include respond(phone) {
      margin: unset;
      height: 100%;
    }
  }
  .text {
    margin-right: auto;
  }
  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: $icon-text-color;
    cursor: pointer;
  }
  .searchDeviceWrapper {
    @include flexbox;
    position: relative;
    margin-left: auto;
    margin-right: 1rem;
    @include respond(phone) {
      margin: unset;
      padding: 0 0;
    }
  }
  .breakLine {
    @include modelBreakLine;
    @include respond(phone) {
      margin-bottom: 0.5rem;
    }
  }
  .iconSearch {
    position: absolute !important;
    @include transform(translate(-50%, -50%));
    top: 50%;
    font-size: 1.3rem;
    right: 7%;
    overflow: hidden;
    color: gray;
  }
  .searchInput::placeholder {
    font-size: $input-label-font-size;
  }
  .searchInput {
    border: none;
    padding: 0.375rem 0.75rem;
    width: 30rem;
    border-radius: 0.25rem;
    font-size: 1.2rem;
    -webkit-appearance: none;
    border: 0.1rem solid $color-border;
    background-clip: padding-box;
    margin-right: -1rem;
    margin-bottom: 0.5rem;
    line-height: 3;
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
    &:focus {
      outline: none;
    }
    @include respond(phone) {
      width: 100%;
      overflow: hidden;
    }
  }
  .tableWrapper {
    width: 100%;
    border-top: 0.5px solid $color-border;
    height: calc(100vh - 40rem);
    @include respond(phone) {
      overflow-y: hidden;
      overflow-x: auto;
      height: 100%;
    }
  }

  .table {
    @include flexbox;
    @include flex-direction(column);
    position: relative;
    max-height: 80%;
    min-height: 80%;
    overflow: auto;
    @include respond(phone) {
      overflow: unset;
      min-height: unset;
    }
  }
  .tableRow {
    @include tableRow;
    text-transform: capitalize;
    cursor: pointer;
    @include respond(phone) {
      flex-wrap: unset;
    }
  }

  .textCell {
    @include textCell;
    @include flex(0 0 23%);
    &:first-child {
      @include flex(0 0 5%);
    }
    &:not(:last-child) {
      border-right: 0.5px solid $color-border;
    }
  }

  .pagination {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .iconwrapper {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-around);
  }
  .toolTip {
    @include toolTip;
  }
  .actionIconEdit {
    font-size: 1.5rem;
    color: $icon-text-color;
    cursor: pointer;
    margin: 0 0.7rem;
  }
  .actionIconEdit:hover .toolTip {
    visibility: visible;
    font-size: 1.4rem;
    color: $icon-text-color;
  }
  .actionIconDel {
    font-size: 1.5rem;
    color: $icon-text-color;
    cursor: pointer;
    margin-left: 0.7rem;
  }
  .actionIconDel:hover .toolTip {
    visibility: visible;
    color: $icon-text-color;
  }
  .close {
    visibility: hidden;
    height: 0 !important;
    width: 0 !important;
    @include transition(width 0.5s);
  }
  .open {
    @include flexbox;
    @include flex-direction(column);
    position: absolute;
    height: 93%;
    width: 50%;
    border-radius: 1rem;
    background-color: #fff !important;
    background-size: cover;
    z-index: 10;
    right: 1rem;
    top: 1rem;
    box-shadow: $shadow-light;
    padding: 2rem 2rem;
    @include transition(width 0.5s);
    visibility: visible;
    @include respond(phone) {
      width: 100%;
      height: 100%;
      padding: unset;
      left: 0;
      top: 0;
      right: 0;
    }
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin: 2rem 0;
    margin-top: auto;
    @include respond(phone) {
      margin-top: auto;
    }
  }
  .closeBtnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    margin-top: auto;
    width: 100%;
    @include respond(phone) {
      margin-top: 1rem;
    }
  }
  .noDataRow {
    @include tableRow;
    @include justify-content(center);
    text-transform: capitalize;
    cursor: pointer;
    @include respond(phone) {
      @include flex-wrap(unset);
      @include justify-content(flex-start);
    }
  }
  .colorStrip {
    padding: 0.3rem;
    width: 10rem;
  }
  .fillColor {
    height: 2rem;
    width: 10rem;
  }
}
