@import "../../../index.scss";
.controlWrappers {
  z-index: 900;
  cursor: pointer;
  @include flexbox;
  @include flex-direction(column);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: none rgb(255, 255, 255);
  width: 4rem;
  height: 8rem;
}
.icon {
  font-size: 2rem;
  color: #717171;
  height: 4rem;
  padding: 1rem;
}
.icon:hover {
  color: black;
}
.separator {
  border-bottom: 1px solid #e7e7e7;
  margin: 0 0.3rem;
}
