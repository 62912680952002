@import "../../index.scss";

.CreateTerminals {

  padding: 1rem 1rem 0 1rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  @include respond(tablet) {
    top: 25%;
  }
  @include respond(phone) {
    padding: 2rem;
    //width: 100%;
    height: 100%;
  }
  .header {
    @include modelHeader;
  }
  .breakLine {
    @include modelBreakLine;
  }
  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: $icon-text-color;
    cursor: pointer;
  }
  
  
  .form {
    @include flex-wrap(wrap);
    @include flexbox;
    margin-right: auto;
    overflow-y: auto;
    overflow-x: hidden;
    @include justify-content(space-between);
    margin: 2rem;
    height: 60%;
    @include respond(phone) {
      padding: 0 1rem;
      max-height: 65%;
      margin:unset;
    }
  }
  .inputWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    padding: 0.5rem;
    @include justify-content(flex-start);
    @include respond(phone) {
      @include flex(unset);
      padding: unset;
      width: 100%;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }
  .destinationWrapper {
    @include flex(0 0 100%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(space-between);
    margin-top: 1rem;
    @include respond(phone){
      margin-top: 0;
    }
  }
  .iconNet {
    color: $icon-text-color;
    margin-right: 0.5rem;
  }
  .destinationTitle {
    font-size: 1.6rem;
    color: black;
    text-transform: capitalize;
  }
  .inputWrapperDestWrapper {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    margin-top: 1rem;
    @include respond(phone) {
      @include flex-direction(column);
      //margin:  0 1rem 0rem 0rem;
      @include flex-wrap(unset);
      @include justify-content(unset);
    }
  }
  .destinationBreakLine {
    @include modelBreakLine;
    margin: 1rem 0;
    width: 100%;
    position: relative;
    @include respond(phone) {
      margin: 0.5rem 0;
    }
  }
  .searchInput::placeholder {
    font-size: $input-label-font-size;
  }
  .searchInput {
    border: none;
    padding: 0.375rem 0.75rem;
    width: 30rem;
    border-radius: 0.25rem;
    font-size: 1.2rem;
    border: 0.1rem solid $color-border;
    background-clip: padding-box;
    margin-right: -1rem;
    margin-bottom: 0.5rem;
    line-height: 3;
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
    &:focus {
      outline: none;
    }
    @include respond(phone) {
      width: 100%;
      overflow: hidden;
    }
  }
  .closeIcon {
    @include flexbox;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .titleCloseWrapper {
    @include flexbox;
    @include justify-content(space-between);
  }
  .btnCreateWrapper {
    @include flexbox;
  }
  .reset {
    font-size: 1.8rem;
    color: $color-iot-green-primary;
  }
  .reset:hover .toolTip {
    top: 8rem !important;
    visibility: visible;
    color: $color-iot-green-primary;
  }
  .btnWrapper {
    // margin-top: auto;
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    @include respond(phone) {
      //margin-top: 1rem;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .resetBtnWrapper {
    display: flex;
    justify-content: flex-end;
  }
  .headerWrapper{
    display: flex;
    justify-content: space-between;
  }
  .stepsWrapper {
    display: flex;
    justify-content: space-between;    
    width:calc(100% - 5rem);
    margin-left: 1rem;
  }
  .stepWrapper {
    display: flex;
    align-items: center;
    //flex:0 0 30%;
  }
  .count{
    background-color: #e2e2e2;
    border-radius: 50%;
    width:2rem;
    line-height:2rem;
    text-align: center;
    padding:1rem;
    margin: 0rem 0.5rem;
    color:#fff;
    @include respond(phone) {
      width:1rem;
    line-height:1rem;
    }
  }
  .stepname{
    text-align: center;
    font-size: 1.2rem;
  }
  .stepIcon {
    margin-right: 1rem;
    text-align: center;
    border-bottom: 0.2rem solid $icon-text-color;    
    height:2rem;
    width:5rem;
  }
  .singleBtnWrapper {
    margin-top: auto;
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    margin-right: 2rem;
    @include respond(phone) {
      margin-top: unset;
      margin-right: unset;
    }
   
  }
  
}
