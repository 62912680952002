@import "../../index.scss";
.Dashboards {
  @include flexbox;
  @include flex-direction(column);
  margin: 1.3rem;
  @include respond(tablet) {
    margin: unset;
  }
  @include respond(phone) {
    margin: unset;
    @include flexbox;
    @include flex-direction(column);
  }
}
.openModel {
  @include transition(width 0.5s);
  width: calc(100vw - 11%);
  height: 100%;
  visibility: visible;
  z-index: 100;
  position: absolute; ////change from reletive
  z-index: 100;
  background-color: #ffff;
  right: 0;
  margin-left: auto;
  overflow: auto; ///added this
  @include respond(phone) {
    width: unset;
  }
}
.fullMode {
  @include transition(width 0.5s);
  width: 100vw;
  height: 100%;
  visibility: visible;
  z-index: 100;
  position: relative;
  z-index: 100;
  right: 0;
  margin-left: auto;
}
.closeModel {
  @include transition(width 1s);
  width: 0%;
  height: 0%;
  visibility: hidden;
  position: relative;
  z-index: -1;
}
.releaseNotesWrapper {
  @include positionCenter;
  top: 35%;
  left: 17%;
  background-color: #fff;
  box-shadow: $shadow-light;
  width: 60rem;
  height: 40rem;
  z-index: 101;
  padding: 1.5rem;
  border-radius: 0.2rem;
  @include respond(phone){
    top: 34%;
    left: 50%;
    width: 90vw;
  }
}
.notes {
  font-weight: 500;
  overflow: auto;
  height: 100%;
  padding: 0 1.5rem 0 1.5rem;
  color: #2b2b2b;
  padding: 1rem;
  white-space: pre-wrap;
  font-size: 1.4rem;
  max-height: 85%;
}
.dateReleaseWrapper {
  @include flexbox;
  width: 100%;
  @include justify-content(flex-start);
  @include align-items(center);
  padding: 0.5rem;
  font-size: 1.2rem;
}
.closeIcon {
  margin-left: auto;
  cursor: pointer;
  font-size: 1.4rem;
  margin-right: 0.5rem;
}
.googleMapWrapper {
  position: relative !important;
  height: calc(100vh - 12.5rem);
  width: 100%;
  @include respond(tablet) {
    height: calc(100vh - 10rem);
  }
  @include respond(phone) {
    height: calc(100vh - 10rem);
  }
}
.googleMapWrapperHeight {
  @include respond(phone) {
    height: 100vh !important;
  }
}
.expandedTables {
  overflow: auto;
  @include respond(phone) {
    overflow-x: hidden;
  }
}

.resultSearchOpen {
  position: absolute;
  width: 80rem;
  height: 40rem;
  right: 0rem;
  z-index: 1;
  padding: 1rem;
  background-color: #fff;
  box-shadow: $shadow-light;
  border-radius: 0 0 0 0.75rem;
  @include transition(width 0.5s);
  @include respond(phone) {
    width: 100%;
    height: 100%;
    padding: unset;
  }
}
.spinnerSearchWrapper {
  @include positionCenter;
}
.breakLine {
  width: 100%;
  margin-top: 1rem;
  border: 0.5px solid $color-border;
}
.wrapperMobileResult {
  @include respond(phone) {
    position: absolute;
    width: 96%;
    padding: 1rem;
    top: 2rem;
  }
}
.titleWrapper {
  @include flexbox;
  @include flex-direction(row);
  width: 100%;
  @include justify-content(space-between);
  @include respond(phone) {
    margin-top: 1rem;
  }
}
.title {
  @include flex(0 0 66%);
  font-size: 1.5rem;
  margin-top: 1rem;
  color: black;
  font-weight: bold;
  text-transform: capitalize;
  @include respond(tab-port-big) {
    font-size: 1.6rem;
    @include flex(unset);
    margin-left: 1rem;
  }
}
.icon {
  font-size: 1.4rem;
  margin: 0.5rem;
  padding: 0.5rem;
  color: black;
  cursor: pointer;
  @include respond(phone) {
    margin-right: 1rem;
  }
}
.resultSearchClose {
  position: absolute;
  width: 0;
  height: 0;
}
.listSearch {
  width: 100%;
  background-color: #fff;
  height: 100%;
  position: absolute;
  overflow: auto;
}
.cardWrapper {
  @include flexbox;
  @include flex-direction(column);
  padding: 1rem;
  font-size: 1.3rem;
  cursor: pointer;
}
.selected {
  background-color: $color-login-page-hiway;
  color: white;
}
.item {
  @include flexbox;
}
.noSearchResult {
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 2rem;
  margin-left: 2rem;
}
.iconPlus {
  @include flexbox;
  @include justify-content(flex-end);
  @include align-items(center);
  margin-right: 0.2rem;
  font-size: 1.3rem;
  color: $color-iot-green-primary;
  font-weight: 600;
  cursor: pointer;
}
.plusLabel {
  margin-right: 0.5rem;
}
.listResult {
  min-height: 10rem;
  overflow: auto;
  max-height: 80%;
}
.label {
  margin-right: 0.5rem;
  font-weight: bold;
}
.openMapMode {
  height: 100vh;
  width: 100%;
  position: relative;
}
