@import "../../index.scss";
.ClearHistory {
  background-color: #fff;
  @include positionCenter;
  padding: 2rem 2rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  box-shadow: $shadow-light;
  height: 51rem;
  width: 30rem;
  z-index: 100;
  .header {
    @include modelHeader;
    font-size: 1.6rem;
  }
  .breakLine {
    @include modelBreakLine;
    @include respond(phone) {
      margin: 0 1rem;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .formWrapper {
    margin-bottom: auto;
    @include flexbox;
    margin-top: 2rem;
    margin-left: 1rem;
    @include flex-direction(column);
    @include justify-content(flex-start);
    height: 100%;
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    position: relative;
    right: -2.2rem;
    @include respond(phone){
      right: 0;
    }
  }
  .btn {
    width: 10rem;
    padding: 1;
  }
  .checkBoxTelemetryWrapper {
    @include flexbox;
    @include flex-direction(column);
    margin-bottom: 2rem;
  &:last-child{
    margin-bottom: 0 ;
  }
  }
  .errMsg{
    font-size: 1.2rem;
    color: $color-red-danger;
    margin-bottom: .5rem;
    margin-left: 1rem;
  }
  
  .inputWrapper {
    width: 100%;
    @include flex-direction(column);
    overflow: hidden;
    @include flexbox;
    position: relative;
    @include respond(tablet) {
      width: calc(100% - 4rem);
    }
    @include respond(phone) {
      &:not(:last-child) {
        margin-bottom: 0.3rem;
      }
    }
  }
}
