@import "../../index.scss";

.Spin {
  height: 100vh;
  width: 95vw;
  overflow: hidden !important;
  .svgLoaderWrapper {
    background-color: #ebebeb;
    @include positionCenter;
    width: 90%;
    height: 85%;
    border-radius: 34px;
    @include respond(phone) {
      height: 80%;
      top: 47%;
    }
  }
  .imgSpinner {
    @include positionCenter;
    width: 300px;
    @include respond(phone) {
      width: 250px;
    }
  }
}
