@import "../../../index.scss";
.SocketLoaders {
  @include positionCenter;
  .msgWrapper{
    @include flexbox;
    justify-content: center;
    align-items: center;
    min-width: 25rem;
  
  }
}
