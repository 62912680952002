@import "../../../index.scss";
.icon {
  position: absolute;
  right: 5%;
  top: 40%;
  font-size: 1.6rem;
  color: $icon-text-color;
  @include respond(phone) {
    top: 50%;
  }
}
.input {
  padding: 0.375rem 0.75rem;
  line-height: 3;
  font-size: 1.2rem;
  color: $color-input-font;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $color-input-border;
  border-radius: 0.25rem;
  &::placeholder {
    text-transform: capitalize;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #e2e2e2;
    cursor: not-allowed;
  }
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
}
.label {
  font-size: $input-label-font-size;
  padding-bottom: 0.8rem;
  @include flexbox;
  @include justify-content(flex-start);
  @include respond(phone) {
    padding-bottom: 0.4rem;
  }
}
.text {
  font-size: $input-label-font-size;
  text-transform: capitalize;
  margin-right: auto;
}
.error {
  @include globalErrorMsg;
  margin-top: 0.3rem;
}
