@import "../../index.scss";
.input {
  padding: 0.375rem 0.75rem;
  line-height: 3;
  font-size: 1.2rem;
  color: $color-input-font !important;
  background-clip: padding-box;
  border: 1px solid #fff;
  font-family: inherit;
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
  &:focus {
    outline: none;
  }
  border-radius: 0.25rem;
  &:not(:disabled) {
    background-color: #fff;
  }
  &:disabled {
    background-color: #e2e2e2;
    cursor: not-allowed;
    @include respond(phone) {
      opacity: 0.8 !important;
      font-weight: bolder !important;
      color: #000 !important;
    }
  }
  &::-webkit-input-placeholder {
    text-transform: capitalize;
    font-size: 1.2rem;
  }
  &::-webkit-datetime-edit {
    color: $color-input-font;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Poppins", sans-serif !important;
  }
}
.genericInput {
  border: 1px solid $color-input-border;
}
.label {
  font-size: $input-label-font-size;
  @include flexbox;
  text-transform: capitalize;
  padding-bottom: 0.8rem;
  @include justify-content(flex-start);
  @include respond(phone) {
    padding-bottom: 0.4rem;
    @include flexbox;
   @include align-items(center)
  }
}
.text {
  font-size: $input-label-font-size;
  text-transform: capitalize;
  margin-right: auto;
}
.lableWhite {
  font-size: $input-label-font-size;
  text-transform: capitalize;
  color: #fff;
}
.error {
  font-size: 1.1rem;
  margin-top: 0.3rem;
  color: $color-red-danger;
}
.errorMobile {
  font-size: 1.1rem;
  color: $color-red-danger;
}
.spinnerWrapper {
  font-size: 1.5rem;
  color: $color-iot-green-primary;
  position: relative;
  margin-left: 1rem;
}

.iconStyles {
  position: absolute;
  right: 1rem;
  top: 55%;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @include respond(phone) {
    top: 70%;
  }
}
input[type="color"] {
  min-height: 3.5rem;
  //height: 100%;
  @include respond(tab-port-big) {
    min-height: 2.9rem;
  }
  width: inherit;
  @include respond(tablet) {
    min-height: 2.8rem;
  }
  @include respond(phone) {
    min-height: 2.8rem;
  }
}
