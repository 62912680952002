@import "../../index.scss";

.ViewTerminals {
  // border-radius: 1rem;
  // @include flexbox;
  // @include flex-direction(column);
  // height: 100%;
  padding: 1rem 1rem 0 1rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  @include respond(tablet) {
    top: 25%;
  }
  @include respond(phone) {
    padding: 2rem;
    //width: 100%;
    height: 100%;
  }
  .header {
    @include modelHeader;
  }
  .breakLine {
    @include modelBreakLine;
  }
  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: $icon-text-color;
    cursor: pointer;
  }
  
  .form {
    @include flex-wrap(wrap);
    @include flexbox;
    margin-right: auto;
    overflow-y: auto;
    overflow-x: hidden;
    @include justify-content(space-between);
    margin: 2rem;
    @include respond(phone) {
      padding: 0 1rem;
      margin:unset;
      padding-right: 0.5rem;
      max-height: 65%;
    }
  }
  .inputWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(flex-start);
    @include respond(phone) {
      @include flex(unset);
      width: 100%;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }
  .destinationWrapper {
    @include flex(0 0 100%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(space-between);
    margin-top: 1rem;
  }
  .iconNet {
    color: $icon-text-color;
    margin-right: 0.5rem;
  }
  .destinationTitle {
    font-size: 1.6rem;
    color: black;
    text-transform: capitalize;
  }
  .inputWrapperDestWrapper {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    margin-top: 1rem;
    @include respond(phone) {
      @include flex-direction(column);
      @include flex-wrap(unset);
      @include justify-content(unset);
    }
  }
  .destinationBreakLine {
    @include modelBreakLine;
    margin: 1rem 0;
    width: 100%;
    position: relative;
    @include respond(phone) {
      margin: 0.5rem 0;
    }
  }
  .searchInput::placeholder {
    font-size: $input-label-font-size;
  }
  .searchInput {
    border: none;
    padding: 0.375rem 0.75rem;
    width: 30rem;
    border-radius: 0.25rem;
    font-size: 1.2rem;
    border: 0.1rem solid $color-border;
    background-clip: padding-box;
    margin-right: -1rem;
    margin-bottom: 0.5rem;
    line-height: 3;
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
    &:focus {
      outline: none;
    }
    @include respond(phone) {
      width: 100%;
      overflow: hidden;
    }
  }
  .closeIcon {
    @include flexbox;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .titleCloseWrapper {
    @include flexbox;
    @include justify-content(space-between);
  }
  .btnCreateWrapper {
    @include flexbox;
  }
  // .reset {
  //   font-size: 1.8rem;
  //   color: $color-iot-green-primary;
  // }
  // .reset:hover .toolTip {
  //   top: 8rem !important;
  //   visibility: visible;
  //   color: $color-iot-green-primary;
  // }
  .btnWrapper {
    margin-top: auto;
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    @include respond(phone) {
      margin-top: 1rem;
    }
  }
  .singleBtnWrapper {
    margin-top: auto;
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    margin-right: 2rem;
    @include respond(phone) {
      margin-top: 1rem;
      margin-right: unset;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
  }
}
