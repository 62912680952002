@import "../../index.scss";

.CreateVehicles {
  padding: 1rem 1rem 0 1rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  @include respond(tablet) {
    top: 25%;
  }
  @include respond(phone) {
    padding: 2rem;
    //width: 100vw;
    height: 100vh;
  }
  .header {
    @include modelHeader;
    @include flexbox;
  }
  .breakLine {
    @include modelBreakLine;
  }

  .infoWrapper {
    @include flexbox;
    @include justify-content(space-between);
    background-color: #fff;
    padding: 1rem 1rem 0 1rem;
    max-height: 70%;
    height: 70%;
    @include respond(phone) {
      padding: 1rem;
      overflow: auto;
      max-height: 65%;
      //height: 65%;
    }
  }

  .iconSearch {
    position: absolute !important;
    right: 5%;
    top: 50%;
    font-size: 1.6rem;
    color: $color-iot-green-primary;
  }

  .label {
    font-size: 1.6rem;
    text-transform: capitalize;
  }
  .error {
    @include globalErrorMsg;
  }
  .stickError {
    @include globalErrorMsg;
    margin-left: 2rem;
    margin-bottom: 0.3rem;
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin-top: auto;
    margin-bottom: 1rem;
    @include respond(phone) {
      margin-top: unset;
      //margin-right: 2rem;
      margin-bottom: unset;
      margin-top: 1rem;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .subtitle {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
  .listWrapper {
    @include flexbox;
    @include flex-direction(column);
    @include flex(0 0 33%);
    padding: 0.5rem;
    @include respond(phone) {
      min-width: 30rem;
      overflow: auto;
      flex: unset;
    }
  }
  .itemsWrapper {
    overflow: auto;
    @include flexbox;
    @include flex-direction(column);
    padding-right: 0.5rem;
    @include respond(tablet) {
      max-height: 30vh;
    }
    @include respond(phone) {
      max-height: 100%;
    }
    margin-top: 0.5rem;
  }
  .btnResetWrapper {
    @include flexbox;
    margin-left: auto;
    margin-right: 2rem;
  }
  .noSearchResult {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
}
