@import "../../../index.scss";
.controlsWrapper {
  @include flexbox;
  margin-top: 1rem;
  margin-left: 1rem;
  position: absolute;
}
.controlsWrapper > * {
  margin-right: 1rem;
}
.controlsWrapper > *:last-child {
  margin-right: 0rem;
}
.filtersWrapper,
.notificationWrapper {
  @include flexbox;
  height: 100%;
  //@include slideTransition;
}
.notificationWrapper,
.overviewWrapper {
  height: 50rem;
  background-color: #fff;
  @include flexbox;
  padding: 2rem;
  margin-left: 1rem;
  @include flex-direction(column);
  width: 50rem;
  position: relative;
  @include respond(phone) {
    width: 100%;
    top: 0;
    width: 75vw;
    height: 55rem;
    position: absolute;
    z-index: 10000;
  }
}
.sosImg {
  width: 2.5rem;
  @include respond(phone) {
    width: 4rem;
  }
}
.overviewWrapper {
  width: 70rem;
  @include respond(phone) {
    width: 85vw;
  }
}
.detailsWrapper {
  @include flexbox;
  margin-top: 1rem;
  height: 100%;
}

.wrapperCloseFilter {
  @include respond(phone) {
    top: 0;
    z-index: 1000;
    position: absolute;
    width: 75vw;
    height: 70vh;
  }
}
.iconNotificationsOnRead {
  color: #fff;
  background-color: green;
}
.iconNotificationPhone {
  color: #fff !important;
}
.closeNotification {
  width: 0;
  height: 0;
}
.viewLicense {
  width: 13rem;
  text-transform: none;
  @include respond(phone) {
    width: unset;
  }
}
.iconMsg {
  width: 5rem;
  @include flexbox;
  @include justify-content(center);
}
.imgIconPhone {
  @include respond(phone) {
    width: 3rem;
  }
}
.msgNoData {
  font-size: 1.4rem;
  padding: 1rem;
  @include flexbox;
  @include justify-content(center);
}
.errorMsg {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  color: $color-red-danger;
  @include flexbox;
  @include justify-content(flex-end);
}
.spinner {
  font-size: 1.8rem;
  font-weight: bolder;
  color: $icon-text-color;
  margin-left: 1rem;
}
.mainWrapper {
  @include flexbox;
  @include flex-direction(column);
  width: 40rem;
  position: relative;
  background: white;
  margin-left: 1rem;
  padding: 2rem;
  @include transition(width 0.25s);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 0.2rem;
  height: 50rem;
}
.header,
.headerAction,
.activeHeader,
.subHeader {
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(center);
}
.header,
.subHeader {
  background-color: $color-login-page-hiway;
  color: #fff;
  position: relative;
}
.header {
  min-height: 5rem;
}
.subHeader {
  padding: 0.5rem;
}
.closeIcon {
  margin-left: auto;
  font-size: 1.6rem;
  padding: 0.5rem;
  cursor: pointer;
  padding: 1rem;
}
.title {
  font-size: 1.8rem;
  margin-left: 0.7rem;
  @include flexbox;
  @include align-items(center);
}
.breakLine,
.breakLineNotification {
  @include modelBreakLine;
}
.breakLineNotification {
  width: 100%;
}
.notification {
  @include flexbox;
  @include flex-direction(column);
  cursor: pointer;
}
.dataInfoWrapper,
.createZoneInfoWrapper {
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  position: relative;
  padding: 1rem;
  @include justify-content(space-between);
  width: 100%;
}
.createZoneInfoWrapper {
  padding-top: 0rem;
  background-color: #ebebeb;
  width: auto;
}
.subTitle {
  font-size: 1.5rem;
}
.headerAction {
  border: 1px solid $color-border;
  padding: 0.5rem;
  cursor: pointer;
  margin-top: 0.5rem;
}
.activeHeader {
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.subTitleActive {
  @include flexbox;
  @include justify-content(flex-start);
}
.iconActive {
  font-size: 1.4rem;
}
.positionWrapper {
  @include flexbox;
  width: 100%;
  margin-top: 1rem;
  @include align-items(center);
  @include flex-wrap(nowrap);
}
.inputPosition {
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include flex(0 0 20%);
  text-transform: capitalize;
  margin-right: 1rem;
}
.btnAddLocation,
.btnAction {
  @include flex(0 0 13%);
  padding: 1.8rem;
  //background-color: $color-form-button-primary;
  position: relative;
  outline: none;
  border: 0.1rem solid #ced4da;
  margin-top: 2.2rem;
  cursor: pointer;
  margin-left: auto;
  &:disabled {
    opacity: 0.6;
  }
}
.btnAction {
  background-color: #fff;
  margin-left: 1rem;
  //border: 0.5px solid #f5f5f5;
  padding: 1rem 1.5rem;
}
.listWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  @include flexbox;
  @include align-items(center);
  @include flex-direction(column);
  height: 100%;
  z-index: 10;
  background-color: #ebebeb;
  width: 100%;
  @include positionCenter;
  margin-top: 0.5rem;
}
.allBoxwrapper {
  display: inline-flex;
  position: absolute;
  top: 6rem;
}
.listItem {
  font-size: 1.4rem;
  padding: 0.5rem;
  //background-color: #fff;//#fafafa;
  border-bottom: 1px solid #fafafa;
  @include align-items(center);
  @include flexbox;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
}
.dataWrapper {
  @include flexbox;
  align-items: center;
}
.iconWrapper {
  margin-left: auto;
}
.trashIcon {
  color: $color-input-font;
  font-size: 1.6rem;
  margin-left: auto;
  z-index: 100;
  padding: 0 0.5rem;
}
.resetIcon {
  color: $color-input-font;
  font-size: 1.4rem;
  margin-left: auto;
  z-index: 100;
  padding: 0 0.5rem;
}
.uploadFileWrapper {
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(flex-start);

  overflow: hidden;
  @include respond(phone) {
    @include flex(unset);
    margin-top: 0.8rem;
  }
}
.inputWrapper {
  margin-top: 1rem;
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include respond(phone) {
    @include flex(unset);
    margin-bottom: 1rem;
  }
}
.btnWrapper,
.btnWrapperDraw {
  @include flexbox;
  @include justify-content(flex-end);
  margin-top: 1rem;
}

.btnWrapperDraw {
  @include justify-content(flex-start);
}
.drawbtnWrapper {
  @include flexbox;
  @include justify-content(flex-end);
  margin-top: 1rem;
  margin-right: -2rem;
}
.btn {
  @include mapButton;
  width: 6rem;
  padding: 0 0.5rem;
  // margin-right: 5.9rem;
  margin-left: unset;
}
.btnDrawLoaction {
  width: 3rem;
  margin-right: auto;
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
}
.mapIcon {
  color: black;
  font-size: 1.3rem;
  @include positionCenter;
}

.titleTypeFencing {
  margin-top: 0.5rem;
  font-size: 1.3rem;
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(center);
}
.btnDrawTypeWrapper {
  @include flexbox;
  margin-top: 0.5rem;
  @include justify-content(space-between);
  //border: 1px solid $color-border;
  padding: 0.5rem 0;
}
.iconT {
  font-size: 2rem;
  color: #666;
  @include positionCenter;
}
.iconClose {
  font-size: 1.6rem;
  color: #fff;
  cursor: pointer;
}
.iconCloseActive {
  color: #666;
}
.iconClose :hover .toolTip {
  visibility: visible;
}
.iconTypeWrapper {
  position: relative;
  width: 5rem;
  height: 3rem;
  display: inline-block;
  cursor: pointer;
  border: 0.1rem solid #ced4da;
  padding: 1rem 3rem;
}
.actionIconTypeWrapper {
  width: 4rem !important;
  height: 2rem;
  margin-left: 1rem;
}
.editIconTypeWrapper {
  width: 0rem !important;
  height: 0.5rem;
  margin-left: 1rem;
  margin-top: 1rem;
}
.editIconT {
  font-size: 1.3rem;
  color: #666;
  @include positionCenter;
}
.iconTDisable {
  pointer-events: none;
  opacity: 0.6;
}
.imageClass {
  width: 100%;
}
.imgNotification {
  width: 2.5rem;
  @include respond(phone) {
    width: 2rem;
    margin-right: auto;
  }
}
.imgWrapperNotification {
  @include flexbox;
  @include align-items(center);
  padding: 1rem 0;
  @include justify-content(flex-start);
  @include respond(phone) {
    width: 100%;
  }
}
.border {
  border: 1px solid $color-border !important;
  width: 100%;
}
.btnScreen,
.btnNotification {
  width: 4rem !important;
  font-size: 1.8rem !important;
  text-align: center;
  padding-right: 0.9rem;
  position: relative;
}
.textNotification {
  margin-left: auto;
  font-size: 1.5rem;
}
.btnNotification {
  width: 9rem !important;
  @include flexbox;
  padding: 1rem 0.7rem;
  @include align-items(center);
  @include respond(phone) {
    display: none;
  }
}
.iconMap {
  color: #666666;
  position: absolute;
  @include transform(translate(-50%, -50%));
  left: 50%;
  top: calc(50% + 0.5rem);
  width: 3rem;
  height: 3rem;
  font-size: 1.8rem;
}
.btnMoveToZoneWrapper {
  @include flexbox;
  margin-top: auto;
  @include justify-content(center);
  @include flex-direction(column);
}
.noZoneMsg {
  font-size: 1.3rem;
}
.dorpDownListWrapper {
  @include positionCenter;
  @include mapButton;
  width: 8rem !important;
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(space-around);
  padding: 0.5rem;
  top: 10.6rem;
  height: 12rem;
}
.itemClusterWrapper {
  @include flexbox;
  @include align-items(flex-start);
  @include flex-direction(column);
  @include justify-content(flex-start);
  border-bottom: 1px solid $color-border;
}
.itemClusterMobile {
  padding: 0.4rem;
  @include flexbox;
}
.itemInfoCluster {
  @include align-items(center);
}
.NotificationMobileWrapper {
  display: none;
  @include respond(phone) {
    display: inline-block;
    @include mapButton;
    height: 4rem;
    width: 9rem !important;
    padding: 1rem 0.5rem;
    position: relative;
  }
}
.container,
.containerBroadcast {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.containerBroadcast {
  height: 37rem;
}
.spinnerLoader {
  @include positionCenter;
  top: 15%;
  z-index: 10;
  font-size: 3rem;
  color: $color-login-page-hiway;
}
.msgWrapper {
  width: 100%;
  @include flexbox;
  font-size: 1.4rem;
  @include justify-content(flex-start);
  @include align-items(center);
  padding: 0.5rem;
}

.msgTextWrapper {
  width: calc(100% - 5rem);
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(flex-start);
  margin-left: 1rem;
}
.topLine,
.bottomLine {
  width: 100%;
  @include flexbox;
  padding: 0.5rem;
  @include justify-content(flex-start);
  @include respond(phone) {
    padding: 0.2rem;
  }
}
.dateMobile {
  display: none;
  @include respond(phone) {
    display: block;
    padding: 0.2rem;
  }
}
.notificationDate {
  margin-left: auto;
  margin-right: 2rem;
  @include respond(phone) {
    display: none;
  }
}
.description {
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
  max-width: 95%;
}
.plusIcon {
  font-size: 1.3rem;
  color: #666666;
  margin-right: 0.4rem;
  font-weight: bold;
}
.container {
  width: 100%;
  margin: 0 auto;
  max-height: calc(100vh - 34rem);
  overflow: auto;
  position: relative;
  overflow-x: hidden;
  @include respond(phone) {
    width: 100%;
    max-height: unset;
    height: calc(100vh - 24rem);
  }
}
.msg {
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;

  .bubble {
    float: left;
    // float: right;
    max-width: 85%;
    width: auto;
    height: auto;
    display: block;
    background: #dadada;
    opacity: 0.86;
    color: blac;
    border-radius: 0.5rem;
    position: relative;
    margin: 1rem 0 0.3rem 2.5rem;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);

    &.alt {
      margin: 1rem 2.5rem 0.3rem 0px;
      background: $color-login-page-hiway;
      opacity: 1;
      float: left;
    }
    &.follow {
      margin: 0.2rem 0 0.3rem 2.5rem;
    }

    &.altfollow {
      margin: 0.2rem 2.5rem 0.3rem 0px;
      background: #dcf8c6;
      float: right;
    }
    .txt {
      padding: 0.8rem 0 0.8rem 0;
      width: 100%;
      @include flexbox;
      min-width: 8rem;
      word-break: break-word;
      @include flex-direction(column);
      .name {
        font-weight: 600;
        font-size: 1.4rem;
        display: inline-table;
        padding: 0 0 0 1.5rem;
        margin: 0 0 0.4rem 0;
        color: #3498db;
        span {
          font-weight: normal;
          color: #b3b3b3;
          overflow: hidden;
        }
        &.alt {
          color: #2ecc51;
        }
      }
      .message {
        font-size: $chat-body-font-size;
        font-weight: 500;
        padding: 0 1.5rem 0 1.5rem;
        // margin: auto; added
        color: #2b2b2b;
        display: table;
        white-space: pre-wrap; ///added
      }
      .timestamp {
        font-size: $chat-time-stamp-font-size;
        padding: 0 1rem;
        @include flexbox;
        @include justify-content(flex-start);
        position: relative;
        text-transform: uppercase;
        color: #999;
        margin-left: 0.2rem; ///added
      }
    }
    .bubbleArrow {
      position: absolute;
      float: left;
      left: -1.1rem;
      top: 0px;
      &.alt {
        bottom: 2rem;
        left: auto;
        right: 4px;
        float: right;
      }
    }
    .bubbleArrow:after {
      content: "";
      position: absolute;
      border-top: 1.5rem solid #dadada;
      border-left: 1.5rem solid transparent;
      border-radius: 4px 0 0 0px;
      width: 0;
      height: 0;
      //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
      //looks like I can't give a shadow to the arrows
    }
    .bubbleArrow.alt:after {
      border-top: 1.5rem solid $color-login-page-hiway;
      transform: scaleX(-1);
    }
  }
}
.statusIcon {
  color: #999;
  margin-left: auto;
}
.wInputContainer {
  width: 50rem;
  box-sizing: border-box;
  padding: 0.9rem 1.8rem 1.1rem;
  margin-right: 0;
  background-color: #dadada;
  border-radius: 1rem;
  border: 1px solid $color-border;
  margin-bottom: 1rem;
  display: inline-block;
  min-height: 7rem;
  position: absolute;
  left: 2rem;
  @include flexbox;
  @include align-items(center);
  z-index: 100;
  bottom: 0rem;
  margin-left: 0.5rem;
  @include respond(phone) {
    width: 90%;
    margin: 0 auto 4rem auto;
  }
}

.sendMsgBtn {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  color: #fff;
  margin-left: auto;
  cursor: pointer;
  @include respond(phone) {
    margin-right: unset;
    position: relative;
    top: 0.3rem;
  }
}

.wInputText {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  background-color: #fff;
  min-height: 3rem;
  padding: 0.3rem;
  outline: 0;
  width: 85%;
  resize: none;
  border: none;
  font-size: $chat-body-font-size;
  border-radius: 1rem;
  font-family: inherit;
  margin-top: 0.3rem;
  z-index: 1000;
  position: relative;
  @include respond(phone) {
    height: 3rem;
    margin-top: unset;
    position: relative;
    top: 0.3rem;
  }
}
.spanOverUsage {
  background-color: $color-login-page-hiway;
}

.toolTip,
.smallTooltip {
  @include toolTip;
}
.smallTooltip {
  padding: 0.5rem;
}

.gpsStatus {
  position: relative;
}
.noTerminalTooltip:hover .smallTooltip {
  visibility: visible;
}
.gpsStatus:hover .toolTip {
  visibility: visible;
}
.msgLength {
  position: absolute;
  bottom: 0.5rem;
  left: 4rem;
  z-index: 100;
}
.downArrow {
  padding: 1rem;
  border-radius: 50%;
  font-size: 1.4rem;
  position: absolute;
  bottom: 0;
  right: 1.5rem;
  cursor: pointer;
  background-color: $color-border;
}
.totalCount {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  position: absolute;
  width: 5rem;
  height: 5rem;
  font-size: 1.3rem;
  z-index: 1000;
  left: -4%;
  top: -37%;
  background-color: #dadada;
  border-radius: 50%;
}
.msCount {
  position: absolute;
  top: 0;
  left: 3.5rem;
  color: #000;
  font-weight: bold;
}
.glow {
  animation: glow linear 2s infinite;
}
@keyframes glow {
  0% {
    background-color: $color-red-danger;
  }
  50% {
    background-color: #dadada;
  }
  100% {
    background-color: $color-red-danger;
  }
}
.dateDivider {
  @include flexbox;
  @include justify-content(center);
}
.countMobileNotification {
  display: none;
  @include respond(phone) {
    display: inline-block;
    font-size: 1.4rem;
  }
}
.date {
  background-color: $color-border;
  color: black;
  padding: 0.5rem 2rem;
  border-radius: 0.6rem;
  margin-top: 0.5rem;
  font-weight: bold;
}
.tableRow {
  @include tableRow;
  @include flexbox;
  border-right: 1px solid #979797;
  border-left: 1px solid #979797;
  padding: 0.5rem 1rem;
  @include align-items(center);
  // @include justify-content(space-between);
  text-transform: capitalize;
  font-weight: unset;
  cursor: pointer;
  &:last-child {
    border-bottom: 1px solid #979797;
  }
  @include respond(phone) {
    @include flex-wrap(nowrap);
    width: 180%;
  }
}

.tableWrapper {
  width: 100%;
  height: 85%;
  margin-top: 0.3rem;
  @include flexbox;
  @include flex-direction(column);
  @include respond(phone) {
    overflow: auto;
  }
}
.textCell {
  @include textCell;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  padding: unset;
  font-size: 1.4rem;
  @include flex(0 0 11%);

  &:nth-child(2) {
    @include flex(0 0 20%);
  }
  &:nth-child(3) {
    @include flex(0 0 20%);
  }
  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.textCellCluster {
  @include textCell;
  @include flexbox;
  @include align-items(center);
  // padding: unset;
  font-size: 1.4rem;
  @include flex(0 0 8%);
  &:nth-child(2) {
    @include flex(0 0 20%);
  }
  &:nth-child(3) {
    @include flex(0 0 25%);
  }

  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.borderbottom {
  border: 1px solid #979797;
}
.imgCenter {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-size: 1.8rem;
}
.itemAgrWrapper {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-size: 1.6rem;
}
.iconFont {
  font-size: 1.8rem;
}
.table {
  @include flexbox;
  overflow: auto;
  max-height: 90%;
  height: 90%;
  @include flex-direction(column);
  margin-bottom: auto;
  position: relative;
  min-height: 10rem;
  // min-height: 46rem;
  @include respond(phone) {
    overflow: unset;
  }
}
.networkId:hover .toolTip {
  visibility: visible;
}
.licenseNumber:hover .toolTip {
  visibility: visible;
  left: 8rem;
}
.imgVehicle {
  width: 2rem;
  height: 2rem;
  @include respond(phone) {
    margin-right: 1rem;
  }
}
.deviceDote {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  @include respond(phone) {
    display: inline-block;
    margin-right: 0.2rem;
    height: 1rem;
    width: 1rem;
  }
}
.iconWrapperMobile {
  @include flexbox;
  @include justify-content(space-around);
  padding-bottom: 0.5rem;
  margin-left: 0.2rem;
}
.deviceDoteArg {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  font-size: 1.8rem;
}
.imgIcon {
  height: 2rem;
  width: 2rem;
  &:last-child {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.loadingDataMsg {
  font-size: 1.4rem;
  padding: 1rem;
}
.aggregationSummary {
  padding: 0.5rem;
  margin-top: 0.5rem;
  @include flexbox;
  @include justify-content(space-around);
  @include align-items(center);
  box-shadow: $shadow-light;
}
.infoIcon {
  font-size: 2rem;
  color: #2196f3;
  //position: relative;
}
.infoWrapper {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(flex-start);
}
.clusterVehiclesWrapper {
  height: 40rem;
  background-color: #fff;
  @include flexbox;
  padding: 2rem;
  margin-left: 1rem;
  @include flex-direction(column);
  width: 50rem;
  position: relative;
  @include respond(phone) {
    width: 100%;
    top: 0;
    width: 55vw;
    //height: 55rem;
    position: absolute;
    z-index: 10000;
  }
}
.multizoneContainer {
  overflow: auto;
  padding-right: 0.5rem;
}
.multizoneWrapper {
  height: 50rem;
  background-color: #fff;
  @include flexbox;
  padding: 2rem;
  margin-left: 1rem;
  @include flex-direction(column);
  width: 40rem;
  position: relative;
  @include respond(phone) {
    width: 100%;
    top: 0;
    width: 75vw;
    height: 55rem;
    position: absolute;
    z-index: 10000;
  }
}
.multizoneHeader {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  background-color: $color-login-page-hiway;
  color: #fff;
  padding: 1rem;
  cursor: pointer;
  position: relative;
}

.refreshIcon {
  background-color: #646464;
  color: #fff;
  font-size: 1.8rem;
  margin-right: 1rem;
  @include flex(0 0 10%);
  height: 5rem;
  text-align: center;
  @include align-items(center);
}
.singlezoneWrapper {
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(center);
  margin-top: 2rem;
}
.fillColor {
  @include flex(0 0 3%);
  background-color: $color-login-page-hiway;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.2rem;
}
.singlezoneItemWrapper {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 100%);
  @include justify-content(space-between);
}
.singlezoneItem {
  @include flexbox;
  @include justify-content(space-between);
  color: #646464;
  @include align-items(center);
  cursor: pointer;
  border-left: 1rem solid #ccc;
  &:nth-child(2) {
    background-color: #f5f5f5;
  }
}
.singlezoneItemDetail {
  @include flexbox;
  background-color: #fff;
  color: #646464;
  border-left: 1rem solid #ccc;

  @include align-items(center);
}
.zoneActionIconWrapper {
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(center);
  background-color: #ebebeb;
  width: 100%;
  height: 100%;
}
.headerAndTableWrapper {
  @include flexbox;
  @include flex-direction(column);
  padding: 1rem;
  @include justify-content(space-between);
  width: 100%;
}
.zoneActionIcon,
.zoneOpenIcon {
  @include flexbox;
  padding: 1rem;
  padding-right: 0.5rem;
  @include align-items(center);
  height: 100%;
  color: #646464;
  font-size: 3rem;
  cursor: pointer;
}
.zoneOpenIcon {
  font-size: 2rem;
  margin-left: auto;
}
.zoneTitle {
  font-size: 1.4rem;
  margin-left: auto;
  margin-right: 1rem;
}

.zoneActionImg {
  width: 4rem;
  height: 3rem;
  margin: 0rem 1rem;
  color: #fff;
  cursor: pointer;
  @include respond(phone) {
    margin-right: unset;
    position: relative;
    top: 0.3rem;
  }
}
.textCellZone {
  @include textCell;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  padding: unset;
  font-size: 1.4rem;
  @include flex(0 0 10%);
  &:first-child {
    @include flex(0 0 58%);
    @include justify-content(flex-start);
  }

  &:not(:last-child) {
    border-right: 0.5px solid $color-border;
  }
}
.fenceVehiclesLoader {
  @include positionCenter;
  left: 95%;
  z-index: 10;
  font-size: 2rem;
  color: #fff;
}

.createZoneWrapper {
  background-color: #fff;
  height: 50rem;
  @include flexbox;
  padding: 2rem;
  margin-left: 1rem;
  @include flex-direction(column);
  width: 40rem;
  position: relative;
  @include respond(phone) {
    width: 100%;
    top: 0;
    width: 75vw;
    height: 55rem;
    position: absolute;
    z-index: 10000;
  }
}
.createZoneBtns {
  @include flexbox;
  @include justify-content(space-between);
  background-color: #ebebeb;
}
.createZoneBtn {
  background-color: #ebebeb;
  padding: 2rem;
  width: 60%;
  font-size: 1.4rem;
  &:first-child {
    width: 40%;
  }
  cursor: not-allowed;
}
.createZoneBtnDisabled {
  border-left: 10px solid #fff;
  border-bottom: 10px solid #fff;
  background-color: #646464;
  color: #fff;
  cursor: pointer;
}
.createZoneBtnDisabledKml {
  border-right: 10px solid #fff;
  border-bottom: 10px solid #fff;
  background-color: #646464;
  color: #fff;
  cursor: pointer;
}
.createZoneActive {
  color: #646464;
  background-color: #ebebeb;
  cursor: not-allowed;
}
.searchDeviceWrapper {
  @include flexbox;
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
  @include respond(phone) {
    margin: unset;
    padding: 0 0;
  }
}

.iconSearch {
  position: absolute !important;
  @include transform(translate(-50%, -50%));
  top: 50%;
  font-size: 1.3rem;
  right: 7%;
  overflow: hidden;
  color: gray;
}
.searchInput::placeholder {
  font-size: $input-label-font-size;
}
.searchInput {
  border: none;
  padding: 0.375rem 0.75rem;
  width: 30rem;
  border-radius: 0.25rem;
  font-size: 1.2rem;
  -webkit-appearance: none;
  border: 0.1rem solid $color-border;
  background-clip: padding-box;
  margin-right: -1rem;
  margin-bottom: 0.5rem;
  line-height: 3;
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
  &:focus {
    outline: none;
  }
  @include respond(phone) {
    width: 100%;
    overflow: hidden;
  }
}
.vehicleIcon img {
  height: 2rem;
}
.vehicleName {
  margin-left: 3rem;
}
.toolTipOverride {
  left: 4.7rem;
}
