@import "../../../index.scss";
.custommarker {
  position: absolute;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
  display: inline-block;
}

