@import "../../index.scss";
.select {
  position: relative;
  width: 100%;
  border-radius: 0.25rem;
  font-family: inherit;
  cursor: pointer;
  line-height: 3;
  font-size: 1.2rem;
  @include respond(tab-port-big) {
    line-height: 2.5;
  }

  border: 1px solid $color-input-border;
  text-transform: capitalize;
  background-image: url(../../assets/chevronDown.svg);
  background-repeat: no-repeat;
  background-size: 1.4rem 1.4rem;
  padding: 0.375rem 0.75rem;
  padding-left: 0.8rem;
  padding-right: 4rem;
  background-color: #fff;
  background-position: calc(100% - 1.3rem);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
}
.label {
  font-size: $input-label-font-size;
  text-transform: capitalize;
  padding-bottom: 0.8rem;
  @include justify-content(flex-start);
  @include flexbox;
  @include respond(phone) {
    padding-bottom: 0.4rem;
  }
}
.text {
  margin-right: auto;
}
.error {
  @include globalErrorMsg;
  margin-top: 0.3rem;
}
.select option {
  font-size: $input-label-font-size;
}
.errorMobile {
  font-size: 1.1rem;
  color: $color-red-danger;
  margin-left: auto;
}
