@import "../../../../index.scss";
.Speedmeter {
  @include flexbox;
  @include align-items(baseline);
  @include respond(phone) {
    margin-bottom: 2rem;
  }
}
.Speedmeter > *{
  margin-right: 1rem;
}
.itemWrapper {
  background: none rgb(255, 255, 255);
  width: 7rem;
  height: 7rem;
  position: relative;
  z-index: 1;
  // margin-right: 1rem;
  border-radius: 50%;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) -4px 0px 7px -1px;
  box-shadow: rgba(0, 0, 0, 0.3) -4px 0px 7px -1px;
}
.inclinationWrapper {
  background: none rgb(255, 255, 255);
  width: 8rem;
  height: 8rem;
  position: relative;
  border-radius: 50%;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) -4px 0px 7px -1px;
  box-shadow: rgba(0, 0, 0, 0.3) -4px 0px 7px -1px;
}
.SpeedmeterWrapper {
  // width: 77rem;
  // height: 6rem;
  overflow: hidden;
  @include positionCenter;
  @include flexbox;
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
}
.inclinationNumber {
  @include positionCenter;
  top: 75%;
  font-size: 1.2rem;
  font-weight: 650;
  color: #666666;
}
.imgWrapper {
  @include positionCenter;
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
}
.imgVehicle {
  width: 4rem;
}
.text {
  font-size: 1.2rem;
  font-weight: 650;
  color: #666666;
  position: relative;
}
.speedData {
  @include positionCenter;
  font-size: 1.3rem;
  font-weight: 650;
  color: #666666;
}
.textUnit {
  position: relative;
  top: 0.4rem;
}
