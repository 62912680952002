@import "../../../index.scss";
.InfoWrapper {
  outline: none;
  border: none;
  padding: 1rem 1rem;
  border-radius: 0.2rem;
  color: #666666;
  font-size: 1.2rem;
  font-weight: 650;
  line-height: 2.4rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: none rgb(255, 255, 255);
  @include respond(phone) {
    margin-bottom: 1.5rem;
  }
}
.img {
  height: 2rem;
  width: 2rem;
  margin-right: 0.2rem;
  display: inline-block;
}
.itemWrapper {
  @include flexbox;
  @include align-items(center);
  margin-right: 0.3rem;
}
.text {
  margin-right: 0.5rem;
}
