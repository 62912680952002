@import "../../index.scss";
.mapWrapper {
  position: relative !important;
  height: 100%;
  width: 100%;
}
.zoomControls {
  @include flexbox;
  position: relative;
  //right: 0.8rem !important;
  bottom: 2.5rem !important;
  z-index: 10;
  margin-left: 1rem; 
  right: 1rem !important;
}
.zoomControls > *{
  margin-right: 1rem; 
}
.zoomControls > *:last-child {
  margin-right: 0rem; 
}
.controlsMap {
  width: auto;
  z-index: 12 !important;
  position: relative;
  height: 0;///added this 
}

.vehicleDetails {
  width: auto;
  height: 90%;
  // z-index: 10000002 !important;/////added
  position: relative;
}
.mapInfo {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 100%);
  position: relative;
  margin-bottom: 2rem;
  margin-left: 1rem;;
}

.mapInfoWrapper {
  left: 0 !important;
}
.leftBtnsWrapper {
  @include flexbox;
  @include flex-direction(row);
  margin-left: 1rem; 
  margin-top:1rem;
  @include respond(phone){
    margin-left: .5rem;
  }
}
.leftBtnsWrapper > *{
  margin-right: 1rem; 
}
.leftBtnsWrapper > *:last-child {
  margin-right: 0rem; 
}
.zoomBtn {
  width: 7rem;
}
.spinnerWrapper {
  @include positionCenter;
}
.btn {
  display: block;
  @include respond(phone) {
    width: 4rem !important;
    font-size: 1.6rem !important;
  }
}
.iconClassZoom {
  position: relative;
  left: 0.3rem;
}
.typesWrapper {
  @include flexbox;
  margin-right: 1rem;
  @include respond(phone) {
    margin-right: unset;
  }
}
.mapButton {
  @include mapButton;
  margin-left: 0rem;
  width: 5rem;
  @include respond(phone) {
    margin-right: 0.5rem;
    height: 5rem;
    padding: 0.2rem;
  }
}
.mapIcon {
  @include flexbox;
  @include flex-direction(column);
  @include align-items(center);
  text-transform: capitalize;
  padding: 0.3rem;
  &:last-child {
    padding-right: unset;
  }
}

.iconTitle {
  font-size: 1.1rem;
  word-wrap: break-word;
}
.imageIcon {
  width: 4rem;
  height: 4rem;
  background-position: center;
  background-size: 4rem 4rem;
  border-radius: 0.2rem;
  display: inline-block;
  &:hover {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
}
.viewLicense {
  width: 13rem !important;
  @include respond(phone) {
    @include flexbox;
    width: 6rem !important;
    align-items: unset;
    padding: unset;
    position: relative;

  }
}
.imageClass {
  width:100%;
  //margin-right: 0.3rem;
  //position: relative;
  //padding-top: 1rem;
}

.btnSave,
.iconRotate {
  outline: none;
  border: none;
  width: 50%;
  background-color: #fff;
  margin-top: 0.5rem;
  cursor: pointer;
  font-size: inherit;
  @include mapButton;
  height: unset;
  margin-left: unset;
  box-shadow: unset;
}
.btnSave {
  border-left: 1.5px solid #f2f2f6;
}
.iconRotate{
  font-size: 1.3rem;
}
.iconRotate span ,.btnSave span{
  position: relative;
  top: .4rem;
}
.btnRotateWrapper {
  @include flexbox;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  width: 10rem;
}
.licenseImg {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: calc(50% + 0.5rem);
  width:60%;
}
