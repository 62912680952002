@import "../../index.scss";

.ResetPassword {
  @include flexbox;
  height: 80vh;
  width: 70vw;
  @include positionCenter;
  z-index: 1;
  @include respond(phone) {
    @include flex-direction(column);
    background-color: #ebebeb;
    height: 100vh;
    width: 100vw;
  }
}
.leftDiv {
  @include flexbox;
  position: relative;
  width: 50%;
  background-color: $color-login-primary-bg;
  border-radius: 4.5rem 0rem 0rem 4.5rem;
  font-size: 1.43rem;
  @include justify-content(center);
  @include respond(phone) {
    display: none;
  }
}
.rightDiv {
  @include flexbox;
  position: relative;
  width: 50%;
  z-index: 1 !important;
  background-color: $color-login-page-hiway;
  border-radius: 0 4.5rem 4.5rem 0;

  @include respond(phone) {
    width: calc(100% - 4rem);
    height: 100%;
    border-radius: 0 10.5rem 0 0;
    top: 9%;
    position: absolute;
    z-index: 1 !important;
  }
}
.img {
  width: 25rem;
  height: auto;
  margin-bottom: 1rem;
}
.signInTitle {
  font-size: 2.2rem;
  color: #fff;
  margin: unset;
  margin-bottom: 1rem;
  @include respond(phone) {
    font-size: 2.4rem;
  }
}
.formWrapper {
  width: 36rem;
  z-index: 1;
  @include positionCenter;
  @include respond(tablet) {
    width: calc(100% - 4rem);
  }
  @include respond(phone) {
    width: 30rem;
    top: 40%;
  }
}
// .fromWrapperCreate {
//   width: 46rem;
//   @include positionCenter;
//   @include respond(tablet) {
//     width: calc(100% - 4rem);
//     overflow: auto;
//   }
//   @include respond(phone) {
//     width: 30rem;
//     top: 40%;
//   }
// }
.text {
  color: #cbcbcb;
  font-size: 1.6rem;
  margin: unset;
  margin-bottom: 0.5rem;
}
// .signUp {
//   color: #fff;
//   font-size: 1.6rem;
//   margin: unset;
//   margin-bottom: 1rem;
//   &:hover {
//     color: #fff;
//     text-decoration: underline;
//     cursor: pointer;
//     font-weight: 900;
//   }
// }
.inputWrapperSignIn {
  width: 100%;
  @include flex-direction(column);
  @include flexbox;
  @include align-items(center);
  position: relative;
  @include respond(tablet) {
    @include align-items(flex-start);
  }
  @include respond(phone) {
    overflow: auto;
    position: relative;
    z-index: 1;
  }
}
.inputWrapper {
  width: 100%;
  @include flex-direction(column);
  overflow: hidden;
  @include flexbox;
  position: relative;
  @include respond(tablet) {
    width: calc(100% - 4rem);
  }
  @include respond(phone) {
    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }
  }
}
// .inputWrapperCreate {
//   @include flex(0 0 48%);
//   @include flex-direction(column);
//   overflow: hidden;
//   @include flexbox;
//   @include respond(tablet) {
//     @include flex(0 0 100%);
//     max-height: 9rem;
//   }
//   @include respond(phone) {
//     @include flex(unset);
//     width: 100%;
//     max-height: 9rem;
//     &:not(:last-child) {
//       margin-bottom: 0.3rem;
//     }
//   }
// }
.btnWrapper {
  margin-top: 2rem;
  width: 100%;
  @include flexbox;
  @include flex-direction(column);
  @include respond(tablet) {
    width: calc(100% - 4rem);
  }
}
// .forgotPassword {
//   color: #dadada;
//   font-size: 1.6rem;
//   cursor: pointer;
//   margin-top: 1rem;
//   &:hover {
//     color: #fff;
//     text-decoration: underline;
//   }
// }
// .createAccoutnWrapper {
//   width: 100%;
//   @include flexbox;
//   @include flex-wrap(wrap);
//   @include justify-content(space-around);
//   @include align-items(center);
//   @include respond(tablet) {
//     height: calc(100vh - 40rem);
//     width: calc(100% - 4rem);
//     overflow: auto;
//   }
//   @include respond(phone) {
//     height: calc(100vh - 48rem);
//     overflow: auto;
//   }
// }
// .btnCreate {
//   @include flex(0 0 99%);
//   margin-top: 2rem;
//   @include respond(tablet) {
//     width: calc(100% - 4rem);
//   }
//   @include respond(phone) {
//     @include flex(0 0 100%);
//   }
// }
// .btnForgetPwd {
//   @include flex(0 0 99%);
//   margin-top: 2rem;
//   @include respond(tablet) {
//     width: calc(100% - 4rem);
//   }
//   @include respond(phone) {
//     @include flex(0 0 100%);
//   }
// }
.imgPreview {
  margin-right: 0.5rem;
  font-size: 1.3rem;
  color: #6b90b3;
  position: relative;
  top: 1rem;
}
.logoSatSmall {
  width: 7rem;
}
.bottomIcon {
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 1rem;
  margin-right: 1rem;
  @include flexbox;
  @include align-items(center);
}
.hiwayMobileLogo {
  display: none;
  @include respond(phone) {
    display: block;
    margin-bottom: 0.5rem;
    height: 4rem;
  }
}
.imgPhoneHeader {
  display: none;
  @include respond(phone) {
    display: block;
    position: absolute;
    top: 3%;
    left: 8%;
  }
}
.loadingIcon {
  font-size: 2rem;
  color: #fff;
  margin-left: 0.8rem;
}
.disabledLink {
  cursor: not-allowed !important;
}
.logoMobileBottom {
  display: none;
  @include respond(phone) {
    display: block;
    @include positionCenter;
    top: 75%;
    left: 50%;
    @include flexbox;
    @include align-items(center);
    width: 100%;
    @include justify-content(center);
  }
}
.imgOnetrackMobileSign {
  width: 17rem;
}
.imgPreviewMobileText {
  color: #fff;
  font-size: 1.6rem;
  position: relative;
  top: 1.5rem;
}
.bottomLogoMobile {
  margin-left: 0.6rem;
  width: 9rem;
}
.mainError {
  @include globalErrorMsg;
  color: #fff;
  font-weight: bold;
}

.btn {
  color: #fff !important;
  width: 100% !important;
  background-color:  $color-input-font !important;
  &:hover {
    cursor: pointer;
  }
}
