@import "./mixin.scss";
@import "./media-query.scss";
::after,
::before {
  box-sizing: inherit;
}
* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  &:focus {
    outline: none;
  }
}
html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 62.5%; //1rem=10px, 10px/16px=62.25
  -webkit-text-size-adjust: none;
  @include respond(tab-port-big) {
    font-size: 50%;
    -webkit-text-size-adjust: none;
  }
}
body {
  font-family: "Poppins", sans-serif !important;
  line-height: 1.6;
  font-weight: 400;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden !important; ////need to check this down the road
  @include respond(phone) {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
}
.noScrollBody {
  overflow: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  scrollbar-width: thin;
  @include respond(phone) {
    width: 0.8rem;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}
//Datetime picker
.rdtPicker {
  @include respond(tab-port-big) {
    width: 80% !important;
  }
  @include respond(tablet) {
    width: 90% !important;
  }
}
.rdtPicker td.rdtActive:hover {
  border-radius: 50%;
}
td.rdtYear:hover {
  border-radius: 50%;
}
td.rdtMonth:hover {
  border-radius: 50%;
}
td.rdtDay:hover {
  border-radius: 50%;
}
td.rdtDay {
  height: 4rem !important;
}
.rdtTime {
  margin-top: 1.5rem;
}
.rdtPicker td.rdtActive {
  background-color: #ffb637 !important;
  border-radius: 50%;
  overflow: hidden;
}
td.rdtMonth {
  height: 5rem !important;
}
td.rdtYear {
  height: 5rem !important;
  width: 5rem !important;
}
.rdtToday:before {
  border-bottom: 7px solid #1a8092 !important;
}
//rdtcounters
.rdtCounter {
  margin: 1rem;
}
.ReactCrop {
  overflow: unset !important;
}
.rdtBtn {
  color: #1a8092;
  font-size: 1.2rem;
}
.rdtSwitch {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.vehicleIcon {
  width: 2.5rem;
  position: absolute;
  margin-bottom: 0.1rem;
  height: 4rem;
  font-size: 2.5rem;
}
#fullscreenWrapper {
  @include respond(phone) {
    width: 100% !important;
    z-index: 10 !important;
  }
}
//OneTrack
// $color-iot-green-primary: #00B6C9;
#speedmeter {
  left: 1rem !important;
  bottom: 2rem !important;
}
#vehicle_map_canvas .gm-style-pbc {
  opacity: 0 !important;
}

$color-iot-green-primary: #00b6c9;
$color-login-page-hiway: #ffb637;
$text-login-pape-hiway: #2c2738;
$color-login-primary-bg: #ebebeb;
$color-login-secondary-bg: #1a8092;
$color-primary-button: #00b6c9;
$color-primary-text: #00b6c9;
$color-secondary-text: #cbcbcb;
$color-controls-text: #ffffff;
$input-font-weight: 400;
$input-label-font-size: 1.3rem;
$color-input-border: #ced4da;
$color-disable: #bdbdbd;
$color-input-font: #646464;
$color-red-danger: #ff1a1a;
$shadow-light: 0px 0px 16px -2px rgba(196, 196, 196, 0.8);
$color-form-button-primary: #7c9cbf;
$color-form-button-secondary: #fff;
$color-border: #f2f2f6;
$color-sub-title: #abaaaa;
$icon-text-color: #646464;
$input-tableData-font-size: 1.3rem;
$header-background-color: #ebebeb;
$color-default-car-group: yellowgreen;
$chat-body-font-size:1.6rem;
$chat-time-stamp-font-size:1.3rem;
/** Toastify **/
.Toastify__toast-container {
  position: unset !important;
  width: 250px !important;
  padding: unset;
  margin-right: 0.5rem;
}
.Toastify__toast {
  min-height: 35px !important;
}
.Toastify__toast-body {
  font-size: 1.2rem;
}
.Toastify__toast--info {
  background: $color-login-page-hiway !important;
  cursor: default !important;
}
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}
// a[href^="http://maps.google.com/maps"]{display:none !important}
// a[href^="https://maps.google.com/maps"]{display:none !important}
// .gmnoprint a, .gmnoprint span, .gm-style-cc {
//   display:none;
// }
// .gmnoprint div {
//   background:none !important;
// }
// a[href^="http://maps.google.com/maps"]
// {
//   z-index: 10 !important;
//   position: relative !important;
// }
// a[href^="https://maps.google.com/maps"]
// {
//   z-index: 10 !important;
//   position: relative !important;
// }
// .gmnoprint div {
//   z-index: 10000000001 !important;
//   position: relative !important;
// }