@import "../../index.scss";

.UpdateAccounts {
  background-color: #fff;
  @include positionCenter;
  left: 74%;
  padding: 2rem 2rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  box-shadow: $shadow-light;
  height: 90%;
  width: 50%;

  @include respond(phone) {
    padding: unset;
    width: 100%;
    @include positionCenter;
    height: 100%;
  }
  .header {
    @include modelHeader;
    padding: 1rem;
  }
  .breakLine {
    @include modelBreakLine;
    @include respond(phone) {
      margin: 0 1rem;
    }
  }

  .infoWrapper {
    @include flexbox;
    @include justify-content(center);
    background-color: #fff;
    padding: 0rem 2rem 2rem 2rem;
    height: 100%;
    overflow: auto;
    flex-direction: row;

    @include respond(phone) {
      padding: 0 2rem;
      max-height: 65%;
      overflow: hidden !important;
    }
  }
  .formWrapper {
    max-height: 100%;
    @include flex-direction(column);
    @include flexbox;
    overflow-y: auto !important;
    width: 99%;
    @include respond(phone) {
      width: 100%;
      max-height: 100%;
      overflow-y: hidden !important;
    }
  }
  .form {
    @include flex-wrap(wrap);
    @include flexbox;
    padding: 2rem 0.4rem 0 0.4rem;
    margin-left: 1rem;
    @include justify-content(space-between);

    @include respond(phone) {
      @include flex-direction(column);
      @include justify-content(unset);
      margin-right: unset;
      @include flex-wrap(nowrap);
      padding: 0rem 0.4rem 0 0.4rem;
      overflow-y: auto;
    }
  }
  .iconSearch {
    position: absolute !important;
    right: 5%;
    top: 50%;
    font-size: 1.6rem;
    color: $color-iot-green-primary;
  }
  .imageWrapperAction {
    @include flex(0 0 100%);
    @include flex-wrap(wrap);
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @include respond(phone) {
      @include flex(unset);
    }
  }
  .uploadFileWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(flex-start);
    margin-top: 2.8rem;
    overflow: hidden;
    @include respond(phone) {
      margin: 1.3rem 0;
      @include flex(unset);
      overflow: unset;
      width: 100%;
    }
  }
  .overrideClass {
    font-size: 1.3rem;
    margin-top: 1rem;
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
  }
  .inputWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    @include respond(phone) {
      @include flex(unset);
    }
  }
  .previewWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    flex-direction: row;
    @include justify-content(flex-start);
    margin-bottom: auto;
    margin-top: auto;
    @include respond(phone) {
      @include flex(unset);
    }
  }

  .mainHeader {
    margin: 1rem auto;
    font-size: 2rem;
    margin-left: 4rem;
    text-transform: capitalize;
    font-weight: 500;
  }
  .logoHub {
    font-size: 2rem !important;
    display: inline-block;
    color: $color-iot-green-primary !important;
  }
  .generalMsg {
    margin-top: 1rem;
    color: $color-red-danger;
    font-size: 1.4rem;
    margin-left: 3rem;
  }

  .mapTitle {
    font-size: 1.6rem;
    font-weight: 400;
  }

  .label {
    font-size: 1.6rem;
    text-transform: capitalize;
  }
  .error {
    @include globalErrorMsg;
  }
  .stickError {
    @include globalErrorMsg;
    margin-left: 2rem;
    margin-bottom: 0.3rem;
  }
  .pwdStickError {
    @include globalErrorMsg;
    margin-bottom: 0.3rem;
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin: 2rem 0;
    @include respond(phone) {
      margin-top: unset;
      margin-right: 2rem;
      margin-top: 2rem;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .tabTitle {
    text-transform: capitalize;
    font-size: 1.6rem;
    margin-right: auto;
    padding-bottom: 1rem;
    margin-left: 1rem;
    font-weight: $input-font-weight;
  }
  .tabsWrapper {
    margin: 1rem;
  }
  .scrollWrapper {
    display: inline-block;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 55vh;
    border-right: 1px solid var(--color-border);
    @include respond(edit-account) {
      max-height: 45vh;
      min-height: 45vh;
    }
  }

  .openWrapper {
    @include flexbox;
    @include flex-direction(column);
    position: absolute;
    height: 86%;
    width: 92%;
    z-index: 11;
    @include transition(width 0.5s);
    visibility: visible;
    background-color: #fff !important;
    margin-top: 0rem;
    margin-bottom: 2rem;
    overflow: auto;
  }
  .closeWrapper {
    visibility: hidden;
    height: 0;
    width: 0;
    @include transition(width 0.5s);
    z-index: -1;
  }

  .titleSpeed {
    font-size: $input-label-font-size;
    @include flexbox;
    text-transform: capitalize;
    padding-bottom: 0.8rem;

    @include respond(phone) {
      padding-bottom: 0.4rem;
      @include flexbox;
    }
  }
  .radioWrapper {
    @include flex(0 0 100%);
    @include flexbox;
    padding: 0 0.5rem;
    position: relative;
    font-size: 1.2rem;
    align-items: center;
    @include justify-content(flex-start);
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    @include respond(phone) {
      @include flex(unset);
    }
  }
  .radio {
    margin-left: 4rem;
  }
  .checkboxMainWrapper {
    @include flex(0 0 45%);
    @include flexbox;
    position: relative;
    @include justify-content(flex-start);
    @include align-items(center);
    margin-top: auto;
    @include respond(phone) {
      margin-top: 1rem;
      @include flex(unset);
      margin-left: 0.5rem;
    }
  }
  .checkboxWrapper {
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(flex-start);
  }
  .inputsInfoWrapper{
    position: relative;
  }
  .integrationpartner {
    position: absolute;
    background-color: #7c9cbf;
    color: #fff;
    padding: 0.3rem 1rem;
    outline: none;
    border: none;
    cursor: pointer;
    right:0;
    text-align: center;
    font-size: 1.4rem;;
    @include respond(phone) {
      top:-2%;
      font-size: 1.2rem;;
    }
    &:disabled {
      @include disabled;
    }
    &:hover {
      @include hover;
    }
  }
}
