@import "../../../index.scss";

.RightMarker {
  width: 10rem;
  height: 3rem;
  font-size: 1.6rem;
  background-color: #fff;
  border-radius: 0.6rem;
  position: relative;
  z-index: 100000 !important;
  padding: 1rem;
  @include flexbox;

  @include justify-content(flex-start);
  @include align-items(center);
  .title {
      cursor: pointer;
  }
  .closeIcon {
    margin-left: auto;
    cursor: pointer;
  }
}
