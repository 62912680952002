@import "../../../index.scss";
.MapType {
  @include flexbox;
  @include respond(phone) {
    //@include flex-direction(column);
    //align-items: center
  }
  .mainWrapper {
    @include mapButton;
    height: 6.68rem;
    margin-top: unset;
    width: 21rem;
    margin-right: 1rem;
    @include respond(phone) {
      height: 5rem;
      //width: 5rem;
      padding: 0.2rem;
      margin-bottom: 1.5rem;
      margin-right: 0.5rem;
    }
  }
  .typesWrapper {
    @include flexbox;
  }
  .mapIcon {
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    text-transform: capitalize;
    padding: 0.3rem;
    &:last-child {
      padding-right: unset;
    }
  }

  .iconTitle {
    font-size: 1.1rem;
    word-wrap: break-word;
  }
  .imageIcon {
    width: 4rem;
    height: 4rem;
    background-position: center;
    background-size: 4rem 4rem;
    border-radius: 0.2rem;
    display: inline-block;

    // &:hover {
    //   box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2),
    //     0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    // }
  }
  .selected {
    border: 1px solid #2196f3;
  }
  .selectedMapButton {
    @include mapButton;
    //margin-right: 1rem;
    width: 5rem;
    height: 6.68rem;
    margin-top: unset;
    @include respond(phone) {
      //margin-right: -0.3rem;
      height: 5rem;
      width: 5rem;
      padding: 0.2rem;
      margin-bottom: 1.5rem;
      margin-right: 0rem;
    }
  }
  .mapIcon {
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    text-transform: capitalize;
    padding: 0.3rem;
    &:last-child {
      padding-right: unset;
    }
  }

  .iconTitle {
    font-size: 1.1rem;
    word-wrap: break-word;
  }
  
}
