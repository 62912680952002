@import "../../index.scss";
.itemWrapper {
  @include flex(0 0 24%);
  list-style: none;
  @include flexbox;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  @include flex-direction(column);
  margin: 0.5rem 0rem;
  margin-right: 0.5rem;
  @include align-items(flex-start);
  border-bottom: 1px solid $color-border;
}
.itemLabel {
  font-size: 1.4rem;
  text-transform: capitalize;
  font-weight: 500;
  color: black;
}
.itemValue {
  font-size: 1.3rem;
  font-weight: 300;
  color: black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
}
