@import "../../index.scss";
.ImagePreviews {
  background-color: white;
  z-index: 10;
  @include flexbox;
  @include flex-direction(column);
  width: fit-content;
  height: 70%;
  overflow: auto;
}
.img{
  background-color: #fff;
}
.btnwrapper {
  @include flexbox;
  @include justify-content(flex-end);
  margin-top: auto;
  margin-bottom: 2rem;
  @include respond(phone){
    margin-top: 2rem;
  }
}
.imageWrapper {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
}
.titleWrapper {
  @include flexbox;
  @include justify-content(space-between);
  margin-bottom: 1rem;
}
.title {
  font-size: 1.7rem;
  color: black;
  text-transform: capitalize;
}
