@import "../../index.scss";

.CreateUsers {
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  @include respond(tablet) {
    top: 25%;
  }
  @include respond(phone) {
    padding: 2rem;
    height: 100%;
  }
}
.header {
  @include modelHeader;
}
.breakLine {
  @include modelBreakLine;
}

.infoWrapper {
  @include flexbox;
  background-color: #fff;
  padding: 0rem 2rem 1rem 2rem;
  height: 80%;
  overflow: auto;
  @include flex-direction(column);
  @include respond(phone) {
    padding: 0 1rem;
    max-height: 65%;
    @include justify-content(unset);
    overflow: auto;
  }
}
.formWrapper {
  @include flexbox;
  @include flex-direction(column);
  @include respond(phone) {
  }
}
.form {
  @include flex-wrap(wrap);
  @include flexbox;
  margin-right: auto;
  padding: 0 0.4rem;
  @include justify-content(space-between);
  margin-top: 1rem;
  margin-bottom: 1rem;
  @include respond(phone) {
    @include flex-direction(column);
    @include justify-content(space-between);
    margin-right: unset !important;
    @include flex(unset);
    padding:unset;
  }
}

.uploadFileWrapper {
  @include flex(0 0 45%);
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(flex-start);
  margin-top: 2.8rem;
  overflow: hidden;
  @include respond(phone) {
    @include flex(unset);
    margin-top: 0.8rem;
  }
}

.inputWrapper {
  @include flex(0 0 45%);
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include respond(phone) {
    @include flex(unset);
    margin-bottom: 1rem;
  }
}

.mainHeader {
  margin: 1rem auto;
  font-size: 2rem;
  margin-left: 4rem;
  text-transform: capitalize;
}
.logoHub {
  font-size: 2rem !important;
  display: inline-block;
  color: $color-iot-green-primary !important;
}
.generalMsg {
  margin-top: 1rem;
  color: $color-red-danger;
  font-size: 1.4rem;
  margin-left: 3rem;
}

.mapTitle {
  font-size: 1.6rem;
  font-weight: 400;
}

.label {
  font-size: 1.6rem;
  text-transform: capitalize;
}
.error {
  @include globalErrorMsg;
}
.stickError {
  @include globalErrorMsg;
  margin-left: 2rem;
  margin-bottom: 0.3rem;
  @include respond(phone) {
    margin-left: 1rem;
  }
}
.btnWrapper {
  @include flexbox;
  @include justify-content(flex-end);
  margin-top: auto;
  @include respond(phone) {
    margin-top: 1rem;
  }
}
.spinnerWrapper {
  @include positionCenter;
}

.editUser {
  background-color: #fff;
  @include positionCenter;
  left: 74%;
  padding: 2rem 2rem;
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  box-shadow: $shadow-light;
  height: 90%;
  width: 50%;
  @include respond(tablet) {
    top: 25%;
  }
  @include respond(phone) {
    padding: unset;
    width: 100%;
    @include positionCenter;
    height: 100%;
  }
}
.updatePassword {
  @include flexbox;
  padding: 0 0.4rem;
  @include justify-content(space-between);
  margin-top: 1rem;
}
.title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.passwordform {
  @include flexbox;
  margin-top: 1rem;
  @include justify-content(space-between);
  @include respond(phone) {
    @include flex-direction(column);
  }
}
.checkboxWrapper {
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(flex-start);
}
.checkboxMainWrapper {
  @include flex(0 0 45%);
  @include flexbox;
  position: relative;
  @include justify-content(flex-start);
  @include align-items(center);
  @include respond(phone) {
    @include flex(unset);
    margin-left: 0.5rem;
  }
}
.colorIcon {
  height: 2.2rem;
  width: 2.2rem;
  background-color: $color-default-car-group;
  border-radius: 50%;
}
.colorLabel {
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
  @include respond(tab-port-big) {
    font-size: 1.2rem;
  }
}
.titleWrapper {
  @include flexbox;
  @include justify-content(space-between);
  @include respond(phone) {
    @include flex(unset);
  }
}
.openWrapper {
  @include flexbox;
  @include flex-direction(column);
  position: absolute;
  height: 88%;
  width: 92%;
  z-index: 11;
  @include transition(width 0.5s);
  visibility: visible;
  background-color: #fff !important;
  margin-top: 0rem;
  margin-bottom: 2rem;
  overflow: auto;
  @include respond(phone) {
    top: 1rem;
    right: 0;
    left: 0;
    padding: 0 1rem;
    height: 100%;
    width: 95%;
  }
}
.closeWrapper {
  visibility: hidden;
  height: 0;
  width: 0;
  @include transition(width 0.5s);
  z-index: -1;
}
.previewWrapper {
  @include flex(0 0 45%);
  @include flexbox;
  position: relative;
  flex-direction: row;
  @include justify-content(flex-start);
  margin-top: 2rem;
  @include respond(phone) {
    @include flex(unset);
    margin-top: 2rem;
  }
}
.btnpreview {
  margin-bottom: 1rem;
}
