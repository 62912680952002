@import "../../index.scss";
.input {
  padding: 0.375rem 0.75rem;
  line-height: 3;
  font-size: 1.2rem;
  color: $color-input-font !important;
  background-clip: padding-box;
  border: 1px solid $color-input-border;
  border-radius: 0.25rem;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &:not(:disabled) {
    background-color: #fff;
  }
  &:disabled {
    background-color: #e2e2e2;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    text-transform: capitalize;
    font-size: 1.2rem;
  }
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
}
.date {
  position: relative !important;
}
.label {
  font-size: $input-label-font-size;
  text-transform: capitalize;
  padding-bottom: 0.8rem;
  @include flexbox;
  @include justify-content(flex-start);
  @include respond(phone) {
    padding-bottom: 0.4rem;
  }
}
.error {
  @include globalErrorMsg;
  margin-top: 0.3rem;
}
.closeIcon {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 54%;
  right: 1% !important;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.5rem;
  @include respond(phone) {
    top: 70%;
  }
}
.calenderIcon {
  display: inline-block;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 54%;
  right: 15% !important;
  cursor: pointer;
  color: $color-login-page-hiway;
  font-size: 1.2rem;
  @include respond(phone) {
    top: 70%;
  }
}
.text {
  font-size: $input-label-font-size;
  text-transform: capitalize;
}
.errorMobile {
  font-size: 1.1rem;
  color: $color-red-danger;
  margin-left: auto;
}
