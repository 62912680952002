@import "../../../index.scss";
.BtnMainFollow {
  z-index: 900;
  //margin-left: 1rem;
  outline: none;
  border: none;
  border-radius: 0.2rem;
  color: #666666;
  font-size: 1.2rem;
  font-weight: 650;
  width: 13rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  //margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: none rgb(255, 255, 255);
  flex-direction: column;
  height: fit-content;
  &:hover {
    @include hover;
  }
  &:disabled {
    @include disabled;
  }
  &:active {
    @include active;
  }
}
ol {
  @include flexbox;
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  margin: 0 auto;
  list-style: none;
}

.BtnMainFollow:hover .subMenu {
  display: block;
  opacity: 1;
  @include flexbox;
  height: unset;
}
.subMenu {
  display: none;
  height: 0;
}
.openFolllowPhoneMode {
  display: block;
  opacity: 1;
  @include flexbox;
  height: unset;
}
.closeFollowPhoneMode {
  display: none;
  height: 0;
}
.menuItem {
  background: #fff;
  padding: 1.1em 0.5em;
  position: relative;
  margin: 0 0.1em;
  transition: border-bottom 0.23s ease-in-out, background 0.23s linear;
  cursor: pointer;
  min-width: 8rem;
  text-align: center;
}
ol .menuItem:first-child {
  margin-top: 0.5rem;
}
li {
  list-style: none;
}
.trailBtn {
  width: 9rem;
  @include respond(phone) {
    display: none !important;
    width: unset;
  }
}
.trailBtnMobile {
  display: none;
  width: 4rem !important;
  font-size: 1.6rem !important;
  text-align: center;
  @include respond(phone) {
    display: block;
  }
}
.iconShowMobile {
  color: #666666;
 
  font-size: 1.8rem;
}
.activeRoad{
  color: $color-login-page-hiway;
}
