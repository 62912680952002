@import "../../index.scss";
.TermainlClose {
  @include transition(width 0.5s);
  height: 0%;
  width: 0%;
}
.vehicles {
  background-color: #fff;
  margin-top: 2rem;
  padding: 0 2rem;
  @include respond(phone) {
    width: 100vw;
    padding: 0 0;
    margin-top: 0;
  }
  .info {
    padding: 0rem 2rem;
    @include flexbox;
    @include flex-direction(column);
    @include respond(phone) {
      padding: 1rem;
    }
  }
  .header {
    @include modelHeader;
    margin-right: 1rem;
    display: flex;
  }
  .text {
    margin-right: auto;
  }
  .title {
    font-size: 2rem;
    margin: 1rem 0 0 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-transform: capitalize;
  }

  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: $icon-text-color;
    cursor: pointer;
  }
  .timesIcon{
    font-size: 1.6rem;
    margin-left: 3rem;
    margin-top: 0.5rem;
    margin-right: .5rem;
    color: $icon-text-color;
    display: none;
    font-weight: bolder;
    cursor: pointer;
    @include respond(phone) {
      display: inline-block
    }
  }
  .timesIcon:hover .toolTip {
    visibility: visible;
    color: $icon-text-color;
  }
  .breakLine {
    @include modelBreakLine;
    @include respond(phone) {
      margin-bottom: 0.5rem;
    }
  }

  .btnWrapper {
    margin-top: auto;
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    margin-right: 4rem;
  }
  .closeBtnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    width: 100%;
    @include respond(phone) {
      margin-top: 1rem;
    }
  }

  //////////////////////////////////
  .dataWrapper {
    @include flexbox;
    @include flex-direction(column);
    padding: 1rem;
    &:first-child {
      @include justify-content(flex-end);
    }
    @include respond(phone) {
      margin: unset;
      height: 100%;
    }
  }
  .tableWrapper {
    width: 100%;
    border-top: 0.5px solid $color-border;
    @include respond(phone){
    overflow: auto
    }
  }

  .table {
    @include flexbox;
    @include flex-direction(column);
    border-top: 0.5px solid $color-border;
    position: relative;
    max-height: 80%;
    min-height: 80%;
    overflow: auto;
    @include respond(phone){
      overflow: unset;
    }

  }
  .tableRow {
    @include tableRow;
    text-transform: capitalize;
    cursor: pointer;
    justify-content: space-around;
    @include respond(phone) {
     @include flex-wrap(nowrap);
     width: 200%;
    }
  }
  .textCell {
    @include textCell;
    @include flex(0 0 9%);
    @include respond(phone) {
      @include flex(0 0 9.22%);
    }
    &:first-child { //1
      @include flex(0 0 5%);
    }
    &:nth-child(8){ //2
      @include respond(phone) {
        @include flex(0 0 15%);
      }
    }
    &:nth-child(10){//3
      @include flex(0 0 5%);
    }
    &:last-child { //4
      @include flex(0 0 12%);
      @include respond(phone) {
        @include flex(0 0 14%);
      }
    }
    &:not(:last-child) {
      border-right: 0.5px solid $color-border;
    }
  }

  .pagination {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .typeText {
    margin-left: 0.3rem;
  }

  .spinnerWrapper {
    @include positionCenter;
  }

  .toolTip {
    @include toolTip;
  }
  .noRecords {
   @include flex(0 0 100%);
    padding-left: 0.5rem;
  }

  .error {
    @include globalErrorMsg;
    margin-right: auto;
    margin-right: 1rem;
  }

  .btn {
    margin-left: auto;
  }
  /////////////new
  .searchDeviceWrapper {
    @include flexbox;
    position: relative;
    margin-left: auto;
    margin-right: 1rem;
    @include respond(phone) {
      margin: unset;
      padding: 0 0;
    }
  }
  .iconSearch {
    position: absolute !important;
    transform: translate(-50%, -50%);
    top: 50%;
    font-size: 1.3rem;
    right: 7%;
    overflow: hidden;
    color: gray;
  }
  .iconSearchClear {
    position: absolute !important;
    right: 0.2rem;
    top: 55%;
    font-size: 1.2rem;
    top: 1rem;
    overflow: hidden;
    cursor: pointer;
    color: gray;
  }

  .openWrapper {
    @include flexbox;
    @include flex-direction(column);
    position: absolute;
    height: 93%;
    width: 50%;
    border-radius: 1rem;
    background-color: #fff !important;
    background-size: cover;
    z-index: 11;
    right: 1rem;
    top: 1rem;
    box-shadow: $shadow-light;
    padding: 2rem 2rem;
    @include transition(width 0.5s);
    visibility: visible;
    @include respond(phone) {
      width: 100%;
      height: 100%;
      padding: unset;
      left: 0;
      top: 0;
    }
  }
  .macId {
    margin-left: 0.2rem;
  }
  .closeWrapper {
    visibility: hidden;
    height: 0;
    width: 0;
   @include  transition(width 0.5s);
    z-index: -1;
  }

  .iconwrapper {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-around);
  }
  .actionIconEdit {
    font-size: 1.5rem;
    color: $icon-text-color;
    cursor: pointer;
    margin: 0 0.7rem;
  }
  .ownerName:hover .toolTip {
    visibility: visible;
    color: $icon-text-color;
  }
  .actionIconEdit:hover .toolTip {
    visibility: visible;
    font-size: 1.4rem;
    color: $icon-text-color;
  }
  .actionIconDel {
    font-size: 1.5rem;
    color: $icon-text-color;
    cursor: pointer;
    margin-left: 0.7rem;
    @include respond(phone) {
      margin-left: unset;
    }
  }
  .actionIconDel:hover .toolTip {
    visibility: visible;
    color: $icon-text-color;
  }

  .searchInput::placeholder {
    font-size: $input-label-font-size;
  }
  .searchInput {
    border: none;
    padding: 0.375rem 0.75rem;
    width: 30rem;
    border-radius: 0.25rem;
    font-size: 1.2rem;
    -webkit-appearance: none;
    border: 0.1rem solid $color-border;
    background-clip: padding-box;
    margin-right: -1rem;
    margin-bottom: 0.5rem;
    line-height: 3;
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
    &:focus {
      outline: none;
    }
    @include respond(phone) {
      width: 100%;
      overflow: hidden;
    }
  }
  .closeIcon {
    @include flexbox;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .titleCloseWrapper {
    @include flexbox;
    @include justify-content(space-between);
  }
  .btnCreateWrapper {
    @include flexbox;
  }
  .noDataRow {
    @include tableRow;
    @include justify-content(center);
    text-transform: capitalize;
    cursor: pointer;
    @include respond(phone) {
      flex-wrap: unset;
      @include justify-content(flex-start);
    }
  }
  .toolTip {
    @include toolTip;
  }
  .externalId:hover .toolTip {
    visibility: visible;
    color: $icon-text-color;
  }
  .vin:hover .toolTip {
    visibility: visible;
    color: $icon-text-color;
  }
}
