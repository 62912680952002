@import "../../index.scss";

.pageNotFounds {
  min-width: 100vh;
  min-height: 100vh;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include respond(phone) {
    min-width: unset;
  }
}
.container {
  margin-top: -10rem;
  text-align: center;
  @include flexbox;
  @include flex-direction(column);
}
.header {
  font-family: inherit;
  color: $color-sub-title;
  font-size: 3rem;
}
.icon {
  color: $color-sub-title;
  font-size: 4rem;
}
.btnWrapper {
  margin: 1rem;
}

.btn {
  color: #fff !important;
  width: 100% !important;
  background-color: $color-login-page-hiway !important;
  &:hover {
    box-shadow: none !important;
  }
}
