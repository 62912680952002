@import "../../index.scss";

.file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer !important;
  &:focus {
    cursor: pointer !important;
  }
}
.inputInfoWrapper {
  @include flexbox;
  @include respond(phone) {
    height: 4rem;
    overflow: hidden;
  }
}
.icon {
  margin-right: 0.5rem;
}
.placeholderText {
  cursor: pointer;
  text-align: left;
  font-size: 1.3rem;
  padding: 0.3rem 0.75rem;
  @include flex(0 0 63%);
  border: 1px solid $color-border;
  border-left: none;
  background-color: $color-border;
  line-height: 3;
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
}
.info {
  flex: 0 0 33%;
}

.labelPlace {
  visibility: hidden;
  font-size: 1.6rem;
  text-transform: capitalize;
  padding-bottom: 0.8rem !important;
}
.label {
  @include flex(0 0 33%);
  padding: 0.3rem 0.75rem;
  background-color: $icon-text-color;
  color: #fff;
  font-size: 1.3rem;
  @include flexbox;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  @include transition(all 0.4s);
  z-index: 1;
  position: relative;
  cursor: pointer !important;
  &:hover {
    @include hover;
  }
  &:active {
    @include active;
  }
  &:disabled {
    @include disabled;
  }
  line-height: 3;
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
}
.error {
  @include globalErrorMsg;
}
.text {
  margin-right: auto;
}
.imageWrapper {
  @include flexbox;
  border: 1px solid $color-border;
  @include justify-content(center);
  width: 12.8rem !important;
  height: 12.8rem !important;
  margin: 1rem auto;
}
.avatar {
  font-size: 10rem;
  overflow: hidden !important;
  color: $color-iot-green-primary;
}
.image {
  height: 100%;
  background-size: cover;
  margin-bottom: 1rem;
  width: 100%;
}
