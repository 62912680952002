@import "../../index.scss";
.input {
  padding: 0.375rem 0.75rem;
  line-height: 3 !important;
  width: 100% !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 1.2rem !important;
  color: $color-input-font !important;
  background-clip: padding-box !important;
  border-radius: 0.25rem !important;
  border: 0.1rem solid $color-input-border !important;
  height: unset !important;
  border-radius: 0.25rem;
  @include respond(tab-port-big) {
    line-height: 2.5 !important;
  }

  &:focus {
    outline: none;
  }

  &:not(:disabled) {
    background-color: #fff;
  }
  &:disabled {
    background-color: #e2e2e2 !important;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    text-transform: capitalize;
    font-size: 1.2rem;
  }
  &::-webkit-datetime-edit {
    color: $color-input-font;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Poppins", sans-serif !important;
  }
}
.genericInput {
  background-color: #fff;
}
.label {
  font-size: $input-label-font-size;
  text-transform: capitalize;
  padding-bottom: 0.8rem;
  @include flexbox;
  @include justify-content(flex-start);
  @include respond(phone) {
    padding-bottom: 0.4rem;
  }
}
.regularLabel {
  font-size: $input-label-font-size;
  padding-bottom: 0.8rem;
  color: #fff;
  @include flexbox;
  @include justify-content(flex-start);
  @include respond(phone) {
    padding-bottom: 0.4rem;
  }
}
.text {
  font-size: $input-label-font-size;
  text-transform: capitalize;
}
.errorMobile {
  font-size: 1.1rem;
  color: $color-red-danger;
  margin-left: auto;
}
.error {
  font-size: 1.1rem;
  margin-top: 0.3rem;
  color: $color-red-danger;
}

.spinnerWrapper {
  font-size: 1.5rem;
  color: $color-iot-green-primary;
  position: relative;
  margin-left: 1rem;
}
