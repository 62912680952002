@import "../../../index.scss";
.FullScreenControls {
  @include flexbox;
  @include flex-direction(column);
  margin-right: 1rem;
  margin-top: 1rem;
  @include respond(phone) {
    margin-right: unset;
  }

  .btnsWrapper {
    @include flexbox;
    @include justify-content(flex-end);
  }

  .btnsWrapper > *:nth-child(2) {
    @include respond(phone) {
      margin-right: 1rem;
    }
  }
  .btn {
    width: 9rem;
    font-size: 1.2rem !important;
    text-align: center;
    @include respond(phone) {
      width: unset;
      display: none;
    }
  }
  .item {
    @include flex(0 0 25%);
    list-style: none;
    @include flexbox;
    @include flex-direction(column);
    margin: 0.5rem 0rem;
    @include align-items(flex-start);
    border-bottom: 1px solid $color-border;
  }
  .btnMobile {
    display: none;
    width: 4rem !important;
    font-size: 1.6rem !important;
    text-align: center;
    @include respond(phone) {
      display: block !important;
    }
  }
  .btnScreen {
    width: 4rem !important;
    font-size: 1.8rem !important;
    text-align: center;
    //padding-right: 0.9rem;
    position: relative;
    &:last-child {
      margin-top: 0.5rem;
    }
    @include respond(phone) {
      margin-top: unset;
    }
  }
  .iconShow {
    color: #666666;
    margin-left: 0.3rem;
  }
  .icon {
    color: #666666;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: calc(50% + 0.5rem);
    width: 3rem;
    height: 3rem;
    font-size: 1.8rem;
  }
  .iconShowMobile {
    color: #666666;
  }
  .telemetryWrapper {
    width: 60rem;
    margin-top: 1rem;
  }
  .dataVehclieWrapper {
    background-color: #fff;
    padding: 1.5rem 1.5rem;
    padding-bottom: 2.5rem;
    box-shadow: $shadow-light;
    @include flexbox;
    border-radius: 0.25rem;
    @include flex-direction(column);
    margin-top: 1rem;
    position: relative;
    z-index: 11 !important;
    @include respond(phone) {
      margin-top: unset;
      margin: 0 1rem;
    }
  }
  .telemetryWrapper {
    width: 60rem;
    @include respond(phone) {
      width: unset;
      margin-top: 0.5rem;
    }
  }
  .title {
    font-size: 1.5rem;
    margin-top: 1rem;
    color: black;
  }
  .subTitle {
    font-size: 1.5rem;
    color: black;
    text-transform: none;

    @include respond(phone) {
      font-size: 1.1rem;
    }
  }
  .speedText {
    font-size: 1.1rem;
    text-transform: lowercase;
  }
  .breakLine {
    width: 100%;
    margin-top: 1rem;
    border: 0.1rem solid $color-border;
  }
  .tableWrapper {
    width: 100%;
    border-top: 0.5px solid $color-border;
    @include respond(phone) {
      overflow-y: hidden;
      overflow-x: auto;
      height: 100%;
    }
  }
  .table {
    @include flexbox;
    @include flex-direction(column);
    position: relative;
    min-height: 5rem;
    @include respond(phone) {
      overflow: unset;
      min-height: unset;
    }
  }
  .tableRow {
    @include tableRow;
    text-transform: capitalize;
    font-weight: unset;
    cursor: pointer;
    @include respond(phone) {
      @include flex-wrap(nowrap);
      width: 150%;
    }
  }
  .textCell {
    @include textCell;
    font-size: 1.4rem;
    @include flex(0 0 15%);
    &:nth-child(2) {
      @include flex(0 0 25%);
    }
    &:first-child {
      @include flex(0 0 5%);
    }

    &:not(:last-child) {
      border-right: 0.5px solid $color-border;
    }
  }
  .closeInclination {
    height: 0;
  }
  .wrapperDetails {
    @include flexbox;
    list-style: none;
    @include flex-wrap(wrap);
    overflow: hidden;
    padding-top: 1.5rem;
  }
  .dotColor {
    margin-right: 0.3rem;
    display: inline-flex;
    width: 50%;
    margin-right: auto;
    @include respond(phone) {
      width: 38%;
      @include flex-direction(column);
    }
  }

  .toolTip {
    @include toolTip;
  }
  .gpsTooltip:hover .toolTip {
    visibility: visible;
    font-size: 1.4rem;
    color: $icon-text-color;
  }
  .dateTooltip:hover .toolTip {
    visibility: visible;
    font-size: 1.4rem;
    color: $icon-text-color;
    left: 9rem;
  }
  .text {
    margin-left: 0.2rem;
    font-size: 1.2rem;
    width: 60%;
    margin-right: auto;
  }
  .inclination {
    height: 12rem;
    @include flexbox;
    @include flex-direction(column);
    position: relative;
    margin-bottom: 3rem;
    @include transition(height 0.25s ease-in);
  }
  .bottomLine {
    margin-top: 7rem;
    position: relative !important;
    width: 21%;
    margin-right: auto;
    background-color: black;
    height: 0.1rem;
    @include flexbox;
    margin-left: 15%;
    @include respond(phone) {
      width: 32%;
      margin-left: 10%;
    }
  }

  .pitchLine {
    width: 40%;
    position: absolute;
    margin-bottom: 0.1rem;
    height: 0.3rem;
    background-color: black;
    overflow: hidden;
  }
  .carPitch {
    width: 8rem;
    position: absolute;
    height: 4rem;
    font-size: 2.5rem;
  }
  .titleWrapperToggle {
    @include flexbox;
    @include justify-content(flex-start);
    @include align-items(center);
  }
  .toggleWrapper {
    @include flexbox;
    @include align-items(center);
  }
  .dataInclination {
    left: 180%;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    top: -1rem;
    display: inline-block;
    position: absolute;
    font-weight: 500;
    width: 150%;
    @include respond(phone) {
      left: 146%;
    }
  }

  .titleWrapper {
    @include flexbox;
    flex-direction: row;
    width: 100%;
    @include justify-content(flex-start);
    margin-top: 0.5rem;
    @include align-items(center);
  }
  .displayIconWarpper {
    @include flexbox;
    @include justify-content(flex-end);
    height: 3rem;
  }
  .displayIconWarpperTop {
    @include flexbox;
    @include justify-content(flex-end);
    height: 3rem;
  }
  .arrow {
    width: 3rem;
    height: 3rem;
    border-radius: 0.25rem;
    position: relative;
    cursor: pointer;
  }
  .iconclose {
    @include positionCenter;
    top: 51%;
    color: $icon-text-color;
    font-size: 1.8rem;
  }
  .radioWrapper {
    margin-right: 1rem;
    font-size: 1.1rem;
  }
  .checkboxWrapper {
    @include flexbox;
    @include align-items(center);
    font-size: 1.2rem;
    margin-right: 1rem;
    width: 10rem;
  }
  .checkBoxTelemetryWrapper {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }
  .typesWrapper {
    @include flexbox;
  }
  .typesWrapperFullScreen {
    @include flexbox;
    @include flex-direction(column);
  }
  .mapTypesWrapper {
    @include flexbox;
  }
  .mapTypesWrapper2 {
    @include flexbox;
    @include flex-direction(column-reverse);
  }
  .mainWrapperStyles {
    top: 8rem !important;
    position: absolute !important;
    height: 28rem !important;
    width: 4rem !important;
  }
  .line {
    width: 2px;
    border-right: 2px solid $color-border;
    height: 1.5rem;
    margin-right: 1rem;
  }
  .wrapperDetailsVehcile {
    @include flexbox;
    list-style: none;
    @include flex-wrap(wrap);
  }
  .arrow:hover .toolTip {
    visibility: visible;
  }
  .setIcon {
    margin-right: auto;
  }
  .noDataRow {
    @include tableRow;
    @include justify-content(center);
    text-transform: capitalize;
    cursor: pointer;
    @include respond(phone) {
      @include justify-content(flex-start);
      @include flex-wrap(nowrap);
    }
  }
  .wrapperFullscreenColumn {
    @include flexbox;
    @include flex-direction(column);
    @include respond(phone) {
      @include align-items(flex-end);
      margin-right: 0.5rem;
    }
  }
  .wrapperFullscreenColumn > * {
    @include respond(phone) {
      margin-bottom: 1rem;
    }
  }
  .externalId {
    font-size: 1.4rem;
    margin-right: 1rem;
    font-weight: bolder;
    @include respond(phone) {
      font-size: 1.1rem;
    }
  }
  .btnScreen {
    width: 4rem !important;
    font-size: 1.8rem !important;
    text-align: center;
    //margin-right: 1rem;
    padding-right: 0.9rem;
    position: relative;
    // @include respond(phone) {
    //   margin-right: 0.5rem;
    // }
  }
  .iconMap {
    color: #666666;
    position: absolute;
    @include transform(translate(-50%, -50%));
    left: 50%;
    top: calc(50% + 0.5rem);
    width: 3rem;
    height: 3rem;
    font-size: 1.8rem;
  }
}
