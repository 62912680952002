@import "../../../index.scss";
.Btn {
  @include mapButton;
  height: 4rem;
  width: 11rem;
  padding: 1rem 1rem;
}
.closeIcon {
  margin-left: auto;
}
.icon {
  margin-right: 0.2rem;
  color: $icon-text-color;
}
.text {
  margin-right: 0.5rem;
}
.tool {
  padding: 1rem 0rem 1rem 0rem;
  margin-right: 0.5rem;
}
.imageIcon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.1rem;
}
.toolTip {
  @include toolTip;
}
.Btn:hover .toolTip {
  visibility: visible;
}
