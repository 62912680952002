@import "../../index.scss";
.CreateGroups {
  border-radius: 1rem;
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  @include respond(tablet) {
    top: 25%;
  }
  @include respond(phone) {
    padding: 2rem;
    height: 100vh;
    overflow: auto;
  }
  .header {
    @include modelHeader;
  }
  .breakLine {
    @include modelBreakLine;
  }

  .infoWrapper {
    @include flexbox;
    background-color: #fff;
    padding: 1rem 1rem 0 1rem;
    @include flex-direction(column);
    height: calc(100% - 13rem);
    @include respond(phone) {
      padding: 1rem;
      padding-bottom: 0;
      max-height: 65%;
    }
  }
  
  .form {
    @include flex-wrap(nowrap);
    @include flexbox;
    @include justify-content(space-between);
    @include respond(phone) {
      @include flex-direction(column);
      @include justify-content(unset);
      margin-right: unset;
      @include flex-wrap(unset);
      width: 100%;
      //height: 70%;
    }
  }
  .inputWrapper {
    @include flex(0 0 48%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(flex-start);
    &:nth-child(2) {
      margin-left: auto;
      @include respond(phone) {
        margin: unset;
      }
    }
    @include respond(phone) {
      @include flex(unset);
    }
  }
  .categoryWrapper {
    @include flexbox;
    @include justify-content(space-between);
    height: 78%;
    
    @include respond(phone) {
      margin-top:1rem;
      @include flex-direction(row);
      height: unset;
      @include flexbox;
      overflow: auto;
    }
  }
  .listWrapper {
    @include flexbox;
    @include flex-direction(column);
    @include flex(0 0 48%);
    @include respond(phone) {
      @include flex(0 0 80%);
    }
    &:first-child {
      margin-right: auto;
      @include respond(phone) {
        margin-right: 2rem;
      }
    }
    // &:last-child {
    //   @include respond(phone) {
    //     margin-top: 2rem;
    //   }
    // }
  }
  .itemsWrapper {
    overflow: auto;
    @include flex-direction(column);
    @include flexbox;
    @include respond(tablet) {
      max-height: 30vh;
    }
    @include respond(phone) {
      height: 85%;
    }
    margin-top: 0.5rem;
  }
  .item {
    font-size: 1.2rem;
    padding: 1rem;
    background-color: #fafafa;
    border: 1px solid #e7e7e7;
    @include flex-direction(row);
    @include align-items(center);
    @include flexbox;
    cursor: pointer;
    &:hover {
      background-color: #e1e1e1;
    }
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
    @include respond(tablet){
      min-height: 10rem;
    }
    @include respond(phone){
      min-height: 10rem;
    }
  }
  .selected {
    z-index: 50;
    color: #fff;
    background-color: $color-iot-green-primary;
    &:hover {
      background-color: $color-iot-green-primary;
    }
  }
  .dataWrapper {
    @include flexbox;
    @include align-items(center);
  }
  .dataLabel {
    font-size: 1.4rem;
    margin-right: 0.5rem;
  }
  .subtitle {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
  .itemInfoWrapper {
    @include flexbox;
    @include flex(0 0 70%);
    @include flex-direction(column);
    margin-right: auto;
  }
  .addIconWrapper {
    font-size: 1.6rem;
    cursor: pointer;
    color: #beb5b5de;
    border: 1px solid #beb5b5de;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    @include flexbox;
    @include justify-content(center);
  }
  .addIcon {
    padding: 0.3rem;
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin-top: auto;
    margin-bottom: 1rem;
    @include respond(phone) {
      margin-top: unset;
      margin-top: 2rem
    }
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .noDataMSg {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
}
