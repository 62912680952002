@import "../../index.scss";
.btn {
  color: #fff;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  width: 14.2rem;
  line-height: 2.4rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  @include respond(tab-port-big) {
    padding: 1rem 1rem;
    width: 11.2rem;
    font-size: 1.3rem;
    line-height: 2rem;
  }
  cursor: pointer;
  &:not(:last-child) {
    border: 1px solid $color-form-button-primary;
    background-color: transparent;
    color: $color-form-button-primary;
  }
  &:not(:last-child):hover {
    @include hover;
  }
  &:last-child {
    margin-left: 1.2rem;
    margin-right: 2rem;
    background-color: $icon-text-color;
    @include respond(phone) {
      margin-right: unset;
    }
  }
  &:last-child:hover {
    @include hover;
  }
  &:disabled {
    @include disabled;
  }
}
