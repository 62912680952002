@import "../../index.scss";
.mainWrapper {
  border-radius: 0.6rem;
  padding: 1rem 2rem;
  background-color: $color-input-font;
  border: none;
  width: 100%;
  height: 4.5rem;
  outline: none;
  position: relative;
  color: #fff;
  position: relative;
  z-index: 10;
  cursor: pointer;
  font-size: 1.3rem;
  text-transform: capitalize;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  @include respond(tab-port-big) {
    font-size: 1.3rem;
  }
}
.mainWrapper:hover .menuItem,
.mainWrapper:active .menuItem {
  display: block;
}

.selectedItem {
  color: #fff;
  margin-left: 4px;
}

.menuItem {
  list-style: none;
  display: none;
  position: absolute;
  z-index: 3;
  border-radius: 0.6rem;
  color: #fff;
  left: 0;
  right: 0;
  font-size: 1.3rem;
  padding: 1rem 1rem;
  background-color: $color-input-font;
}
.avoidClicks {
  pointer-events: none;
}
.menuItem li {
  text-transform: capitalize;
  padding-top: 1rem;

  &:first-child {
    border-top: 0.5px solid white;
  }
  &:not(:last-child) {
    border-bottom: 0.5px solid white;
    padding-bottom: 1rem;
  }
  &:last-child {
    padding-bottom: 0.5rem;
  }
}
