@import "../../../index.scss";
.ShortcutMenus {
  min-width: 20rem;
  width: auto;
  border: solid 1px $color-border;
  border-radius: 0.6rem;
  box-shadow: $shadow-light;
  padding: 1rem;
  position: absolute;
  background-color: #fff;
  @include flexbox;
  @include flex-direction(column);
  @include align-items(flex-start);
  z-index: 2 !important;
  white-space: nowrap;
  min-height: 5rem;
  padding-top:1.5rem;
  .item {
    width: 85%;
    font-size: 1.4rem;
    padding: 1rem;
    cursor: pointer;
    border: 1px solid $color-border;
    margin: 0.5rem;
    z-index: 2 !important;
    color: #000;   
  }

  .titleWrapper {
    position: relative;
  }
  .title {
    font-size: 1.6rem;
    color: black;
    @include align-items(center);
    margin-left: 0.5rem;
  }

  .imgSat {
    width: 2rem;
    height: 2rem;
    margin-right: 0.3rem;
    position: relative;
  }

  .rightIcon {
    font-size: 1.6rem;
    color: $color-login-page-hiway;
  }
  .titleIconWrapper {
    @include flexbox;
    @include align-items(center);
  }
  .iconSat,
  .closeIcon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.3rem;
    position: relative;
    top: 0.4rem;
  }
  .closeIcon {
    position: absolute;
    font-size: 1.2rem;
    right: -1rem;
    cursor: pointer;
    //padding: 0.5rem;
  }
  .selectedItem {
    color: #fff;
    background-color: $color-login-page-hiway;
  }
  .satIcon {
    font-size: 1.6rem;
    color: $color-login-page-hiway;
    margin-right: 0.5rem;
  }
  .iconSat {
    width: 2rem;
    height: 2rem;
    margin-right: 0.3rem;
    position: relative;
    top: 0.4rem;
  }
}
